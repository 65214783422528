import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

const Dropdown = (props) => {
    const { options, value, onChange, getOptionLabel, label, placeholder, error } = props
    return (
        <Autocomplete
            {...props}
            options={options || []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            key={option => option.id}
            disableClearable
            // disablePortal
            autoSelect={false}
            fullWidth
            size="small"
            onChange={onChange}
            value={value || null}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="outlined"
                    error={error}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                    }}
                    label={label}
                    placeholder={placeholder}
                />}
        />
    )
}

export default Dropdown;