import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import moment from 'moment';
import { getProjectData } from "../../components/Actions/ProjectAction";
import { getCompanyData } from "../../components/Actions/CompanyActions";
import { getPaymentData, handlePaymentDelete } from "../../components/Actions/PaymentActions";
import ConfirmDialog from "../../components/DeleteDialog";
import PaymentForm from "../../components/PaymentForm";
import { useRecoilValue } from "recoil";
import { PaymentlisttState, companyState, projectState } from "../../Actions";
import CustomTable from "../../components/Modules/CustomTable";
export default function BasicTable() {
    const [editData, setEditData] = useState({});
    const paymentListData = useRecoilValue(PaymentlisttState)
    // console.log(paymentListData);
    const [searchInput, setSearchInput] = useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [DeleteId, setDeleteId] = useState('');
    const [OpenPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        getProjectData(projectState)
        getCompanyData(companyState)
        getPaymentData()
    }, [])

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Date',
            selector: row => moment(row?.date, 'YYYY-MM-DD').format("DD-MM-YYYY"),
            sortable: true,
        }, {
            name: 'Project Name',
            selector: row => row?.project_info?.p_name,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: row => row?.company_info?.c_name,
            sortable: true,
        },
        {
            name: 'Transaction Type',
            selector: row => row?.payment_type,
            sortable: true,
        },
        {
            name: 'Payment Type',
            selector: row => row?.payment_mode_info?.payment_mode,
            sortable: true,
        },
        {
            name: 'Account Number',
            selector: row => row?.payment_account,
            sortable: true,
        }, {
            name: 'Amount',
            selector: row => row?.amount,
            sortable: true,
        }, {
            name: 'Note',
            selector: row => row?.note,
            sortable: true,

        }, {
            name: 'Action',
            selector: (row, index) =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        setEditData(paymentListData?.payment?.find((a) => a.id === row.id))
                        setOpenAddModal(true)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "15%"
        },
    ]


    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            color="success"
            onClick={() => {
                // alert("sss")
                setEditData({})
                setOpenAddModal(true)
            }}
            className='btn-success'
            variant="contained"
            size='small'
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add New Payment
        </Button>

    </Stack>;

    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />

    const filteredItems = paymentListData?.payment?.filter(item =>
        item?.payment_type?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.payment_account?.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
    // console.log(filteredItems);
    return (
        <>
            <CustomTable
                title='Payment Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
                subHeaderComponent={searchComponent}
            />

            <PaymentForm
                editData={editData}
                onClose={() => setOpenAddModal(false)}
                openAddModal={openAddModal}
            />

            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handlePaymentDelete(DeleteId, setOpenPopup)} />
        </>

    );
}
