import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import CustomTable from "../../components/Modules/CustomTable";
import { useNavigate } from 'react-router-dom';
import { getInvoiceDetails, handleInvoiceDelete } from "../../components/Actions/InvoiceAction";
import { useRecoilValue } from "recoil";
import { InvoiceDetailsState } from "../../Actions";
const Invoice = () => {

    useEffect(() => {
        getInvoiceDetails()
    }, [])

    const navigate = useNavigate();
    const invoiceDetails = useRecoilValue(InvoiceDetailsState)
    // console.log(invoiceDetails);
    // const [editData, setEditData] = useState({});
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [DeltId, setDeltId] = useState('');

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "7%"
        },
        {
            name: 'Mobile Number',
            selector: row => row?.mobile_number,
            sortable: true,
            width: "15%"
        },
        {
            name: 'Customar Name',
            selector: row => row?.customer_name,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: "10%"
        },
        {
            name: 'State',
            selector: row => row?.state,
            sortable: true,
            width: "10%"
        },
        // {
        //     name: 'Pincode',
        //     selector: row => row?.pincode,
        //     sortable: true,
        //     width: "10%"
        // },
        {
            name: 'Remarks',
            selector: row => row?.remarks,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Advance Received',
            selector: row => row?.advance_received,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Payment Reference',
            selector: row => row?.payment_reference,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        // setEditData(companyData.find((a) => a.id === row.id))
                        navigate(`/invoice-add?invoice-id=${row?.id}`)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeltId(row.id)
                        // console.log(row.id);
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "12%"
        },
    ]
    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                navigate('/invoice-add');
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add Invoice Mode
        </Button>
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    // const filteredItems = invoiceData?.filter(item => (
    //     item?.customer_name.toLowerCase().includes(searchInput.toLowerCase())
    // ));
    return (
        <>

            <CustomTable
                title='Invoice Details'
                columns={columns}
                data={invoiceDetails}
                actions={actions}
                subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleInvoiceDelete(DeltId, setOpenPopup)}
            />

        </>
    );
}
export default Invoice;