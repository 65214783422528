import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import { Stack } from '@mui/material';
import { submitdata, handleExpensesTypeEdit } from "../components/Actions/ExpensesTypeAction";
import CustomDialog from "./Modules/CustomDialog";
import TextInput from "./Modules/TextInput";

const ExpensesTypeForm = (props) => {
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
    })
    return (
        <Dialog
            onClose={onClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    title: editData?.title
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    if (values?.id) {
                        handleExpensesTypeEdit(values, actions, onClose)
                    } else {
                        submitdata(values, actions, onClose)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                }) => (
                    <>
                        <CustomDialog
                            open={open}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Expense Details"
                        >
                            <Stack
                                spacing={2}
                            >
                                <TextInput
                                    label="Expenses type"
                                    value={values.title}
                                    // onChange={(e) => { setFieldValue('title', e.target.value) }}
                                    //   OR
                                    name="title"
                                    onChange={ handleChange }
                                    // OR
                                    //  onChange={ handleChange('title') }
                                />
                                {errors.title && <span style={{ color: 'red' }}>{errors.title && touched.title ? errors.title : ""}</span>}
                            </Stack>
                        </CustomDialog>
                    </>
                )}
            </Formik>
        </Dialog>

    )
}
export default ExpensesTypeForm;