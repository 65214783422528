import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react"

const CustomDialog = (props) => {
    const { children, title, open, onClose, onSubmit, loading, maxWidth } = props
    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error" autoFocus variant="contained">Close</Button>
                <LoadingButton
                    onClick={onSubmit}
                    loading={loading}
                    color="success"
                    autoFocus
                    variant="contained">
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDialog