
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { ExpensesState } from '../../Actions';
import AxiosApi from '../../Actions/AxiosApi';

export const getExpensesData = () => {
    AxiosApi.get("expenses")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(ExpensesState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("expenses", error);
        })
}

export const submitdata = (values, actions, onClose) => {
    AxiosApi.post("expenses", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("expense-type-add", error);
        })

}

export const handleEditSubmit = (values, actions, onClose) => {
    AxiosApi.patch(`expenses/${values?.id}`, values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("expense-type-add", error);
        })

}

export const handleExpensesDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`expenses/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getExpensesData()
                toast.success(data.message);
                setOpenPopup(false);
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })

}
