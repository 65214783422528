import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import CustomTable from "../../components/Modules/CustomTable";
import { useNavigate } from 'react-router-dom';
import { getPurchaseData, handlePurchaseDelete } from "../../components/Actions/PurchaseAction";
import { useRecoilValue } from "recoil";
import { PurchaseState } from "../../Actions";
import moment from "moment";
const Purchase = () => {

    useEffect(() => {
        getPurchaseData()
    }, [])

    const navigate = useNavigate();
    const purchaseData = useRecoilValue(PurchaseState);
    console.log(purchaseData);
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [DeleteId, setDeleteId] = useState('');

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "2%"
        },
        {
            name: 'Vendor name',
            selector: row => row?.vendor_info?.vendor_name,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Deliver to',
            selector: row => row?.deliver_to,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Deliver date',
            selector: row => moment(row?.delivery_date).format('DD-MM-YYYY'),
            sortable: true,
            width: "10%"
        },
        {
            name: 'Payment Terms',
            selector: row => row.payment_mode_info?.payment_mode,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Purchase Order',
            selector: row => row.purchase_order,
            sortable: true,
            width: "10%"
        }, {
            name: 'Referance',
            selector: row => row.reference,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Shipment preference',
            selector: row => row.shipment_preference,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        // setEditData(companyData.find((a) => a.id === row.id))
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "15%"
        },
    ]

    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                navigate('/purchase-add');
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add Purchase Mode
        </Button>
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = purchaseData?.filter(item => (
        item?.reference.toLowerCase().includes(searchInput.toLowerCase())
    ));
    return (
        <>

            <CustomTable
                title='Purchase Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
                subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
            onClick={() => handlePurchaseDelete(DeleteId, setOpenPopup)}
            />

        </>
    );
}
export default Purchase;