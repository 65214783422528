import React, { useState, useEffect } from "react";
import PaybleForm from "../../components/PaybleForm";
import ButtonGroup from '@mui/material/ButtonGroup';
import { LxIcon } from '../../resources/LxIcon';
import { Button, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import ConfirmDialog from "../../components/DeleteDialog";
import { getData, handleDelete } from '../../../src/components/Actions/PayableActions'
import { useRecoilValue } from "recoil";
import { PaybleState } from "../../Actions";
import CustomTable from "../../components/Modules/CustomTable";

export default function BasicTable() {
    const PaybleData = useRecoilValue(PaybleState)
    const [OpenPopup, setOpenPopup] = useState(false);
    const [DeleteId, setDeleteId] = useState('');
    const [searchInput, setSearchInput] = React.useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editData, setEditData] = useState({});

    useEffect(() => {
        getData()
    }, [])

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "15%"
        }, {
            name: 'Account number',
            selector: row => row.account_number,
            sortable: true,
            width: "15%"
        }, {
            name: 'IFSE Code',
            selector: row => row.ifsc_code,
            sortable: true,
            width: "15%"
        }, {
            name: 'Bank name',
            selector: row => row.bank_name,
            sortable: true,
            width: "15%"
        }, {
            name: 'branch_name',
            selector: row => row.branch_name,
            sortable: true,
            width: "15%"
        }, {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        setEditData(PaybleData.find((a) => a.id === row.id))
                        setOpenAddModal(true)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "15%"
        },
    ]
    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                setEditData({})
                setOpenAddModal(true)
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add New Payble Account
        </Button>
        < PaybleForm
            onClose={() => setOpenAddModal(false)}
            open={openAddModal}
            editData={editData} />
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = PaybleData?.filter(item => (
        item?.name.toLowerCase().includes(searchInput.toLowerCase())
    ));

    return (
        <>
            <CustomTable
                title='Payble Account Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
                subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleDelete(DeleteId, setOpenPopup)} />
        </>
    );
}
