import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import { Stack } from '@mui/material';
import CustomDialog from "./Modules/CustomDialog";
import TextInput from "./Modules/TextInput";
import Dropdown from "./Modules/Dropdown";
import { handlePaymentModeEdit, PaymentModeAdd } from "./Actions/PaymentModeAction";
import { useRecoilValue } from "recoil";
import { PaymentModeState } from "../Actions";

const PaymentModeForm = (props) => {
    const paymentModeData = useRecoilValue(PaymentModeState);
    // console.log(paymentModeData);
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        payment_type_id: Yup.string()
            .required('Payment type is required'),
        payment_mode: Yup.string()
            .required('Payment mode is required'),
        payble_account_id: Yup.string()
            .required('Account holder name is required'),
    })
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    payment_type_id: editData?.payment_type_id,
                    payment_mode: editData?.payment_mode,
                    payble_account_id: editData?.payble_account_id
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // PaymentModeAdd(values, actions, onClose)
                    if (values?.id) {
                        handlePaymentModeEdit(values, actions, onClose)
                    } else {
                        PaymentModeAdd(values, actions, onClose)
                    }
                    // console.log(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <>
                        <CustomDialog
                            open={open}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Payment Mode Details"
                        >
                            <Stack
                                spacing={2}
                            >
                                <Dropdown
                                    label="Payment Type"
                                    options={paymentModeData?.payment_type_info}
                                    getOptionLabel={option => option.title}
                                    value={paymentModeData?.payment_type_info?.find((p) => p.id === values.payment_type_id)}
                                    onChange={(e, value) => {
                                        setFieldValue('payment_type_id', value?.id);
                                        // console.log(value);
                                    }}
                                />
                                {errors.payment_type_id && <span style={{ color: 'red' }}>{errors.payment_type_id && touched.payment_type_id ? errors.payment_type_id : ""}</span>}
                                <TextInput
                                    label="Payment Mode"
                                    name="payment_mode"
                                    value={values.payment_mode}
                                    onChange={(e) => { setFieldValue('payment_mode', e.target.value) }}
                                />
                                {errors.payment_mode && <span style={{ color: 'red' }}>{errors.payment_mode && touched.payment_mode ? errors.payment_mode : ""}</span>}
                                <Dropdown
                                    label="Payble Account Name"
                                    options={paymentModeData?.payble_account_info}
                                    getOptionLabel={option => option?.name}
                                    value={paymentModeData?.payble_account_info?.find((p) => p.id === values.payble_account_id)}
                                    onChange={(e, value) => {
                                        setFieldValue('payble_account_id', value.id)
                                        console.log(value);
                                        // setFieldValue('payble_account_id', e.target.value)

                                    }}
                                />
                                {errors.payble_account_id && <span style={{ color: 'red' }}>{errors.payble_account_id && touched.payble_account_id ? errors.payble_account_id : ""}</span>}
                            </Stack>
                        </CustomDialog>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}
export default PaymentModeForm;