import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import CustomTable from "../../components/Modules/CustomTable";
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import { VendorState } from "../../Actions";
import { getVendorDetails, handleVendorDelete } from "../../components/Actions/VendorAction";

const Vendor = () => {
    const VendorData = useRecoilValue(VendorState)
    console.log(VendorData);
    useEffect(() => {
        getVendorDetails()
    }, [])

    const navigate = useNavigate();
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [DeleteId, setDeleteId] = useState('');

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "2%"
        },
        {
            name: 'Vendor Name',
            selector: row => row.vendor_name,
            sortable: true,
            width: "10%"
        }, {
            name: 'Company Name',
            selector: row => row.company_name,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "12%"
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Pan Number',
            selector: row => row.pan_number,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile_number,
            sortable: true,
            width: "10%"
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Payment Mode',
            selector: row => row.payment_mode_info?.payment_mode,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        navigate(`/vendor-add?vendor-id=${row?.id}`)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "12%"
        },
    ]

    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                navigate('/vendor-add');
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add Vendor
        </Button>
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = VendorData?.filter(item => (
        item?.vendor_name.toLowerCase().includes(searchInput.toLowerCase())
    ));
    return (
        <>

            <CustomTable
                title='Vendor Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
            subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
            onClick={() => handleVendorDelete(DeleteId, setOpenPopup)}
            />

        </>
    );
}
export default Vendor;