import React, { useState, useEffect } from "react";
import ExpensesTypeForm from "../../components/ExpensesTypeForm";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import { getExpensesTypeData, handleExpensesTypeDelete } from "../../components/Actions/ExpensesTypeAction";
import ConfirmDialog from "../../components/DeleteDialog";
import { ExpensesTypeState } from "../../Actions";
import { useRecoilValue } from "recoil";
import CustomTable from "../../components/Modules/CustomTable";

export default function BasicTable() {
  const expenseTypeData = useRecoilValue(ExpensesTypeState)
  const [editData, setEditData] = useState({});
  // console.log("Testing edit", editData);
  const [OpenPopup, setOpenPopup] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DeleteId, setDeleteId] = useState('');
  useEffect(() => {
    getExpensesTypeData()
  }, [])

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: "5%"
    },
    {
      name: 'Expense Title',
      selector: row => row.title,
      sortable: true,
      width: "27%"
    }, {
      name: 'Action',
      selector: row =>
        <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
          <Button onClick={() => {
            setEditData(expenseTypeData.find((a) => a.id === row.id))
            setOpenAddModal(true)
          }}><LxIcon name="FaRegEdit" type="fa" /></Button>
          <Button onClick={() => {
            setDeleteId(row.id)
            setOpenPopup(true)
          }}
            color="error"><LxIcon name="BiTrash" type="bi" /></Button>
        </ButtonGroup>,
      sortable: false,
      width: "15%"
    },
  ]
  const actions = <Stack spacing={2} direction="row">
    <Button
      sx={{
        backgroundColor: "#57b8c1"
      }}
      className='btn-success'
      variant="contained"
      size='small'
      onClick={() => {
        setEditData({})
        setOpenAddModal(true)
      }}
      startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
    >
      Add New Title
    </Button>
    <ExpensesTypeForm
      onClose={() => setOpenAddModal(false)}
      open={openAddModal}
      editData={editData}
    />
  </Stack>;
  const searchComponent = <OutlinedInput
    placeholder="Search"
    value={searchInput}
    onChange={(e) => setSearchInput(e.target.value)}
    size="small"
    endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
    variant="outlined"
  />
  const filteredItems = expenseTypeData?.filter(item => (
    item?.title.toLowerCase().includes(searchInput.toLowerCase())
  ));
  return (
    <>
     <CustomTable
        title='Expense Title List'
        columns={columns}
        data={filteredItems}
        actions={actions}
        subHeaderComponent={searchComponent}
      />
      {/* 
      <DataTable
        title='Expense Title List'
        actions={actions}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={filteredItems}
        fixedHeader
        persistTableHead
        subHeader
        subHeaderComponent={searchComponent}
        paginationResetDefaultPage={true}
        pagination={true}
        currentPage={1}
        paginationPerPage={10}

        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All'
        }}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      /> */}
      <ConfirmDialog
        open={OpenPopup}
        onOpen={() => setOpenPopup(false)}
        onClick={() => handleExpensesTypeDelete(DeleteId, setOpenPopup)} />

    </>
  );
}
