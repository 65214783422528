
import { redirect } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
// import { getRecoil } from 'recoil-nexus';
import { authState } from '../Actions';

const PrivateRoute = ({ children }) => {
      const tokenData = useRecoilValue(authState)
      if (!tokenData) {
            return redirect("/login");
      }
      else {
            return children;
      }
}

export default PrivateRoute;