
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { projectState } from '../../Actions';
import AxiosApi from "../../Actions/AxiosApi";

export const getProjectData = () => {
  AxiosApi.get("project")
    .then(function ({ data }) {
      if (data.status) {
        setRecoil(projectState, data.data)
        // console.log(data.data);
      }
    })
    .catch(error => {
      console.log("expenses", error);
    })
}
export const submitData = (values, actions, onClose) => {
  AxiosApi.post("project", values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        getProjectData()
        toast.success(data.message);
      }
    })
    .catch(error => {
      console.log("Get Profile", error);
      actions.setSubmitting(false)
      actions.setErrors({ email: "Something went wrong. Please try again." })
    })
}
export const handleProjectEdit = (values, actions, onClose) => {
  AxiosApi.patch(`project/${values?.id}`, values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        getProjectData()
        toast.success(data.message);
      }
    })
    .catch(error => {
      console.log("project", error);
    })

}
export const handleProjectDelete = (id, setOpenPopup) => {
  AxiosApi.delete(`project/${id}`)
    .then(function ({ data }) {
      if (data.status) {
        getProjectData()
        toast.success(data.message);
        setOpenPopup(false);
      } else {
        toast.error(data.message);
      }
    })
    .catch(error => {
      console.log("Get project", error);
    })

}