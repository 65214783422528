
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { PaymentModeState } from '../../Actions';
import AxiosApi from "../../Actions/AxiosApi";

export const getPaymentModeData = () => {
  AxiosApi.get("payment-mode")
    .then(function ({ data }) {
      if (data.status) {
        setRecoil(PaymentModeState, data.data)
       // console.log(data.data);
      }
    })
    .catch(error => {
      console.log("PaymentModeState", error);
    })
}

export const PaymentModeAdd = (values, actions, onClose) => {
  AxiosApi.post("payment-mode-add", values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        getPaymentModeData()
        toast.success(data.message);
      }
    })
    .catch(error => {
      console.log("expense-type-add", error);
    })
}

export const handlePaymentModeEdit = (values, actions, onClose) => {
  AxiosApi.post(`payment-mode-edit/${values?.id}`, values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        getPaymentModeData()
        toast.success(data.message);
      }
    })
    .catch(error => {
      console.log("payment mode", error);
    })

}

export const handlePaymentModeDelete = (id, setOpenPopup) => {
  AxiosApi.delete(`payment-mode-delete/${id}`)
    .then(function ({ data }) {
      if (data.status) {
        getPaymentModeData()
        toast.success(data.message);
        setOpenPopup(false);
      } else {
        toast.error(data.message);
      }
    })
    .catch(error => {
      console.log("Get Payble", error);
    })

}