import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import {  Stack } from '@mui/material';
import CustomDialog from "./Modules/CustomDialog";
import TextInput from "./Modules/TextInput";
import { CategoriesAdd, CategoriesEdit } from "./Actions/CategoriesAction";

const CategoriesForm = (props) => {
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Categorie is required'),
    })
    return (
        <Dialog
            onClose={onClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    title: editData?.title
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    if (values?.id) {
                        CategoriesEdit(values, actions, onClose)
                    } else {
                        CategoriesAdd(values, actions, onClose)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                }) => (
                    <>
                        <CustomDialog
                            open={open}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Categorie Details"
                        >
                            <Stack
                                spacing={2}
                            >
                                <TextInput
                                    label="Categories"
                                    value={values.title}
                                    // onChange={(e) => { setFieldValue('title', e.target.value) }}
                                    //   OR
                                    name="title"
                                    onChange={ handleChange }
                                    // OR
                                    //  onChange={ handleChange('title') }
                                />
                                {errors.title && <span style={{ color: 'red' }}>{errors.title && touched.title ? errors.title : ""}</span>}
                            </Stack>
                        </CustomDialog>
                    </>
                )}
            </Formik>
        </Dialog>

    )
}
export default CategoriesForm;