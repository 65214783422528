import axios from "axios";
import { toast } from "react-toastify";
import { loadingState } from ".";
import { getRecoil, resetRecoil } from "recoil-nexus";
import { authState } from "./Atoms";

export const apiUrl = process.env.NODE_ENV === "development" ? `https://dishashree.com/bill_app/api/` : `https://dishashree.com/bill_app/api/`
export const imagePath = process.env.NODE_ENV === "development" ? `https://dishashree.com/bill_app/api/` : `https://dishashree.com/bill_app/api/`
const siteUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://web.appitrace.com"

const AxiosApi = axios.create({
  baseURL: apiUrl,
  responseType: "json"
});

const requestHandler = request => {
  const accessToken = getRecoil(authState)
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
};

const responseHandler = response => {
  // console.log("response", response);
  resetRecoil(loadingState)
  return response;
};

const errorHandler = error => {
  console.log("Api error", error.response.status);
  resetRecoil(loadingState)
  if (error.response.status === 401 || error.response.status === 403) {
    resetRecoil(authState);
    window.location.replace(siteUrl + "/login")
  } else if (!error.response.status >= 200 || !error.response.status <= 299) {
    toast.error("Something went wrong. Please try again.")
  }
  return Promise.reject(error);
};

AxiosApi.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

AxiosApi.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default AxiosApi;