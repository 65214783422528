import React from "react"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextInput from "./TextInput";

const DateInput = (props) => {
    const {label, value, onChange} = props
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} >
            <DesktopDatePicker
                label={label}
                name="deadline"
                inputFormat="DD-MM-yyyy"
                value={value}
                size='small'
                onChange={onChange}
                renderInput={(params) => <TextInput {...params} />}
            />
        </LocalizationProvider>
    )
}

export default DateInput