import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { CategorieState, ItemEditState, ItemState } from '../../Actions';
import AxiosApi from '../../Actions/AxiosApi';

export const getItemData = () => {
    AxiosApi.get("item")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(ItemState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("item", error);
        })
}

export const ItemSubmit = (values, actions, navigate) => {
    AxiosApi.post("item", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/items')
                getItemData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            console.log("ItemSubmit", error);
        })

}

export const handleItemEdit = (values,id, actions, navigate) => {
    AxiosApi.post(`item/${id}`, values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/items')
                getItemData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("item Edit", error);
        })

}
export const getEditData = (itemid) => {
    AxiosApi.get(`show/${itemid}`)
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(ItemEditState, data.data?.item)
                setRecoil(CategorieState, data.data?.category_list)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("item Update", error);
        })
}

export const handleItemDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`item/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getItemData()
                toast.success(data.message);
                setOpenPopup(false)
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })

}
