import { setRecoil } from "recoil-nexus";
import { InvoiceDetailsState, InvoiceEditState, InvoiceState } from "../../Actions";
import AxiosApi from "../../Actions/AxiosApi";
import { toast } from 'react-toastify';
export const getInvoiceData = () => {
    AxiosApi.get("invoice-data")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(InvoiceState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("invoice-data", error);
        })
}
export const getInvoiceDetails = () => {
    AxiosApi.get("invoice")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(InvoiceDetailsState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("invoice-data", error);
        })
}
export const InvoiceSubmit = (values, actions, navigate) => {
    AxiosApi.post("invoice-add", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/invoice')
                getInvoiceDetails()
                toast.success(data.message);
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            console.log("Invoice Submit", error);
        })

}
export const handleInvoiceEdit = (values,id, actions, navigate) => {
    AxiosApi.post(`invoice-edit/${id}`, values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/invoice')
                getInvoiceDetails()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("invoice Edit", error);
        })

}
export const getInvoiceEditData = (invoiceid) => {
    AxiosApi.get(`get-invoice/${invoiceid}`)
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(InvoiceEditState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("invoice Update", error);
        })
}
export const handleInvoiceDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`invoice-delete/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getInvoiceDetails()
                toast.success(data.message);
                setOpenPopup(false)
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get Invoice", error);
        })

}
