import React from "react"
import DataTable from "react-data-table-component"

const CustomTable = (props) => {
const {title, actions, columns, data, subHeaderComponent} = props
    
    const customStyles = {
        header: {
            style: {
                minHeight: '40px',
            },
        },
        headRow: {
            style: {
                height: '10px',
                minHeight: '40px',
                backgroundColor: "#57b8c1"
            },
        },
        headCells: {
            style: {
                fontSize: '12px',
                fontWeight: '700',
                padding: '10px'
            },
        }
    }

    const conditionalRowStyles = [
        {
            when: row => row.UserStat === 'Inactive',
            style: {
                backgroundColor: '#f3f3f3',
                color: 'rgb(132 132 132 / 87%)'
            },
        }
    ]

    return (
        <DataTable
            aria-label="customized table"
            title={title}
            actions={actions}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            columns={columns}
            data={data}
            fixedHeader
            persistTableHead
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={true}
            pagination={true}
            currentPage={1}
            paginationPerPage={10}
            paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: 'All'
            }}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
        />
    )
}

export default CustomTable