import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import { Stack } from '@mui/material';
import { submitdata } from './Actions/CompanyActions';
import CustomDialog from "./Modules/CustomDialog";
import TextInput from "./Modules/TextInput";

const CompanyForm = (props) => {
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        c_name: Yup.string()
            .required('Company Name is required'),
        c_info: Yup.string()
            .required('Company Inform is required'),
    })
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    c_name: editData?.c_name,
                    c_info: editData?.c_info
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    submitdata(values, actions, onClose)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <>
                        <CustomDialog
                            open={open}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Company Details"
                        >
                            <Stack
                                spacing={2}
                            >
                                <TextInput
                                    label=" Company Name"
                                    value={values.c_name}
                                    onChange={(e) => { setFieldValue('c_name', e.target.value) }}
                                />
                                {errors.c_name && <span style={{ color: 'red' }}>{errors.c_name && touched.c_name ? errors.c_name : ""}</span>}
                                <TextInput
                                    label=" Company Info"
                                    value={values.c_info}
                                    onChange={(e) => { setFieldValue('c_info', e.target.value) }}
                                />
                                {errors.c_info && <span style={{ color: 'red' }}>{errors.c_info && touched.c_info ? errors.c_info : ""}</span>}
                            </Stack>
                        </CustomDialog>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}
export default CompanyForm;