// import { resetRecoil, setRecoil } from 'recoil-nexus';
import { ProfileState, loadingState, authState } from "../../Actions";
import { toast } from 'react-toastify';
import AxiosApi from '../../Actions/AxiosApi';
import { resetRecoil, setRecoil } from "recoil-nexus";

/* submitdata is a function name form login Api */
export const loginAction = (values, navigate, actions) => {
  AxiosApi.post("login", values)
    .then(function ({ data }) {
      actions.setSubmitting(false)
      if (data.status) {
        setRecoil(authState, data.token)
        navigate('/dashboard')
      } else {
        actions.setErrors({ email: data.message })
      }
    })
    .catch(error => {
      console.log("Get Profile", error);
      actions.setSubmitting(false)
      actions.setErrors({ email: "Something went wrong. Please try again." })
    })
}

export const getProfileData = () => {
  setRecoil(loadingState, {
    type: "screen",
    status: true
  })
  AxiosApi.get("profile")
    .then(({ data }) => {
      if (data.status) {
        setRecoil(ProfileState, data.data)
      } else {
        toast.warn(data.message)
      }
    })
    .catch((error) => {
      console.log("Get Profile", error);
    })
}

export const handleLogout = (navigate) => {
  AxiosApi.post('logout')
    .then(function ({ data }) {
      if (data.status) {
        resetRecoil(authState)
        navigate('/login')
      } else {
        toast.warn(data.message)
      }
    })
    .catch((error) => {
      console.log("Logout Action", error);
    })
}
