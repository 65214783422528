import React from "react";
import Dialog from '@mui/material/Dialog';
import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { submitdata } from './Actions/PayableActions';
import * as Yup from 'yup'
import CustomDialog from "./Modules/CustomDialog";
import TextInput from "./Modules/TextInput";

const PaybleForm = (props) => {
    const { open, onClose, editData } = props;

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        account_number: Yup.number()
            .required('Mobile number is required')
            .typeError('Please fill with numbers'),
        ifsc_code: Yup.string()
            .required('Message is required'),
        bank_name: Yup.string()
            .required('Message is required'),
        branch_name: Yup.string()
            .required('Branch name is required'),
    })
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    id: editData?.id,
                    name: editData?.name,
                    account_number: editData?.account_number,
                    ifsc_code: editData?.ifsc_code,
                    bank_name: editData?.bank_name,
                    branch_name: editData?.branch_name
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    submitdata(values, actions, onClose)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    // / and other goodies /
                }) => (
                    <Stack
                        display={"flex"}
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={2}
                        sx={{
                            backgroundColor: "#Cyan"

                        }}>
                        <CustomDialog
                            open={open}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Account Details"
                        >
                            <TextInput
                                label="Account Holder Name"
                                type="string"
                                variant="outlined"
                                sx={{
                                    py: 1
                                }}
                                value={values.name}
                                onChange={(e) => {
                                    setFieldValue('name', e.target.value)
                                }}

                            />
                            {errors.name && <span style={{ color: 'red' }}>{errors.name && touched.name ? errors.name : ""}</span>}
                            <TextInput
                                label="Account No"
                                type="number"
                                variant="outlined"
                                sx={{
                                    py: 1
                                }}
                                value={values.account_number}
                                onChange={(e) => {
                                    setFieldValue('account_number', e.target.value)
                                }}

                            />
                            {errors.account_number && <span style={{ color: 'red' }}>{errors.account_number && touched.account_number ? errors.account_number : ""}</span>}
                            <TextInput
                                type="string"
                                label="IFSC Code"
                                variant="outlined"
                                sx={{
                                    py: 1
                                }}
                                value={values.ifsc_code}
                                onChange={(e) => {
                                    setFieldValue('ifsc_code', e.target.value)
                                }}
                            />
                            {errors.ifsc_code && <span style={{ color: 'red' }}>{errors.ifsc_code && touched.ifsc_code ? errors.ifsc_code : ""}</span>}

                            <TextInput
                                type="string"
                                label="Bank Name"
                                variant="outlined"
                                sx={{
                                    py: 1
                                }}
                                value={values.bank_name}
                                onChange={(e) => {
                                    setFieldValue('bank_name', e.target.value)
                                }}
                            />
                            {errors.bank_name && <span style={{ color: 'red' }}>{errors.bank_name && touched.bank_name ? errors.bank_name : ""}</span>}

                            <TextInput
                                type="string"
                                label="Branch Name"
                                variant="outlined"
                                sx={{
                                    py: 1
                                }}
                                value={values.branch_name}
                                onChange={(e) => {
                                    setFieldValue('branch_name', e.target.value)
                                }}

                            />
                            {errors.branch_name && <span style={{ color: 'red' }}>{errors.branch_name && touched.branch_name ? errors.branch_name : ""}</span>}

                        </CustomDialog>
                    </Stack>
                )
                }
            </Formik>
        </Dialog>
    )
}
export default PaybleForm;