import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Stack, TableFooter, TableHead } from '@mui/material';
import Grid from '@mui/material/Grid';
import Dropdown from "./Modules/Dropdown";
import TextInput from "./Modules/TextInput";
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useFormik } from "formik";
import { getInvoiceData, getInvoiceEditData, handleInvoiceEdit, InvoiceSubmit } from "./Actions/InvoiceAction";
import { useRecoilValue } from "recoil";
import { InvoiceEditState, InvoiceState } from "../Actions";
import * as Yup from 'yup'
import { LxIcon } from "../resources/LxIcon";
import ConfirmDialog from "./DeleteDialog";
import { toast } from 'react-toastify';
import { decimalNumber } from "../resources/NumberFormat";
import { setRecoil } from "recoil-nexus";
const InvoiceForm = () => {
    let invoiceid = (new URLSearchParams(window.location.search)).get("invoice-id")
    // console.log(invoiceid);
    const [OpenPopup, setOpenPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [editData, setEditData] = useState({});
    const invoiceData = useRecoilValue(InvoiceState);
    const invoiceEditData = useRecoilValue(InvoiceEditState);
    const itemData = invoiceData?.item_data ? invoiceData?.item_data : []
    const paymentMode = invoiceData?.payment_mode ? invoiceData?.payment_mode : []
    const StateData = invoiceData?.state ? invoiceData?.state : []
    const [itemlist, setItemList] = useState(invoiceEditData?.invoice_item ? invoiceEditData?.invoice_item : []);

    // console.log(invoiceEditData);
    const navigate = useNavigate();
    useEffect(() => {
        getInvoiceData()
        getInvoiceEditData(invoiceid)
        invoiceid === null && setRecoil(InvoiceEditState, {})
    }, [invoiceid])

    let sum = decimalNumber(itemlist?.reduce((a, b) => a + b.total, 0));
    const validation = Yup.object().shape({
        mobile_number: Yup.string().required('Mobile Number is required'),
        customer_name: Yup.string().required('Name is required'),
        address: Yup.string().required('Address is required'),
        pincode: Yup.string().required('Pincode is required'),
    })
    const Invoiceformik = useFormik({
        initialValues: {
            id: invoiceid ? invoiceid : '',
            mobile_number: invoiceEditData?.mobile_number ? invoiceEditData?.mobile_number : '',
            customer_name: invoiceEditData?.customer_name ? invoiceEditData?.customer_name : '',
            customer_email: invoiceEditData?.customer_email ? invoiceEditData?.customer_email : '',
            address: invoiceEditData?.address ? invoiceEditData?.address : '',
            state: invoiceEditData?.state ? invoiceEditData?.state : '',
            pincode: invoiceEditData?.pincode ? invoiceEditData?.pincode : '',
            remarks: invoiceEditData?.remarks ? invoiceEditData?.remarks : '',
            advance_received: invoiceEditData?.advance_received ? invoiceEditData?.advance_received : '',
            payment_mode_id: invoiceEditData?.payment_mode_id ? invoiceEditData?.payment_mode_id : '',
            payment_reference: invoiceEditData?.payment_reference ? invoiceEditData?.payment_reference : '',
            item_list: invoiceEditData?.invoice_item ? invoiceEditData?.invoice_item : []
        },
        enableReinitialize: true,
        validationSchema: validation,
        onSubmit: (values, actions) => {
            if (values?.id) {
                handleInvoiceEdit(values, values?.id, actions, navigate)
            }
            else {
                InvoiceSubmit(values, actions, navigate)
            }
        },
    });

    const validationSchema = Yup.object().shape({
        item_id: Yup.string().required('Item is required'),
        quantity: Yup.string().required('Quantity is required'),
    })
    const formik = useFormik({
        initialValues: {
            item_id: editData?.item_id ? editData?.item_id : '',
            item_name: editData?.item_name ? editData?.item_name : '',
            sales_price: editData?.sales_price ? editData?.sales_price : '',
            gst_tax_rate: editData?.gst_tax_rate ? editData?.gst_tax_rate : '',
            quantity: editData?.quantity ? editData?.quantity : '',
            gst_amount: editData?.gst_amount ? editData?.gst_amount : '',
            unit: editData?.unit ? editData?.unit : '',
            total: editData?.total ? editData?.total : ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            {
                if (itemlist?.find((i) => i.item_id === values?.item_id)) {
                    toast.error("This item already exists!!")
                    actions?.setSubmitting(false)
                } else {
                    actions?.setSubmitting(false)
                    const newArr = [...itemlist, values];
                    setItemList(newArr)
                    actions.resetForm()
                    Invoiceformik.setFieldValue('item_list', newArr)
                    setEditData({})
                }
            }
        },
    });
    const handleListDelete = (deleteId, setOpenPopup) => {
        if (itemlist?.find((i) => i.item_id === deleteId)) {
            setItemList(itemlist?.filter((i) => i.item_id !== deleteId))
            setOpenPopup(false)
            toast.success("Item delete successfully")
        } else {
            toast.error("Item Not found!!")
        }
    }
    const handleListEdit = (item_id) => {
        if (itemlist?.find((i) => i.item_id === item_id)) {
            setEditData(itemlist?.find((i) => i.item_id === item_id))
            setItemList(itemlist?.filter((i) => i.item_id !== item_id)) /*to delete list item*/
        } else {
            toast.error("Item Not found!!")
        }
    }
    // console.log(editData);
    return (
        <Stack spacing={2} direction="row">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={12}><h2>Add Invoice Details</h2></Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        error={Invoiceformik.errors.mobile_number && Invoiceformik.touched.mobile_number ? Invoiceformik.errors.mobile_number : ""}
                        type="number"
                        label="Mobile Number"
                        value={Invoiceformik.values?.mobile_number}
                        onChange={(e) => { Invoiceformik.setFieldValue('mobile_number', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        error={Invoiceformik.errors.customer_name && Invoiceformik.touched.customer_name ? Invoiceformik.errors.customer_name : ""}
                        type="string"
                        label="Customar Name"
                        value={Invoiceformik.values?.customer_name}
                        onChange={(e) => { Invoiceformik.setFieldValue('customer_name', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        type="email"
                        label="Customar Email"
                        value={Invoiceformik.values?.customer_email}
                        onChange={(e) => { Invoiceformik.setFieldValue('customer_email', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        error={Invoiceformik.errors.address && Invoiceformik.touched.address ? Invoiceformik.errors.address : ""}
                        type="string"
                        label="Address"
                        minRows={3}
                        multiline
                        value={Invoiceformik.values?.address}
                        onChange={(e) => { Invoiceformik.setFieldValue('address', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <Dropdown
                        // label="State"
                        placeholder="State"
                        options={StateData}
                        getOptionLabel={option => option.name}
                        value={StateData?.find((p) => p.name === Invoiceformik.values.state) || null}
                        onChange={(e, value) => {
                            //   console.log(value);
                            Invoiceformik.setFieldValue('state', value?.name);
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        error={Invoiceformik.errors.pincode && Invoiceformik.touched.pincode ? Invoiceformik.errors.pincode : ""}
                        type="number"
                        label="Pincode"
                        value={Invoiceformik.values?.pincode}
                        onChange={(e) => { Invoiceformik.setFieldValue('pincode', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} sx={{
                        "& .MuiTableCell-root": {
                            p: .5
                        }
                    }}>
                        <Table size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Item Name:</b></TableCell>
                                    <TableCell><b>Rate:</b></TableCell>
                                    <TableCell><b>UOM:</b></TableCell>
                                    <TableCell><b>Quantity:</b></TableCell>
                                    <TableCell><b>GST%</b></TableCell>
                                    <TableCell><b>GST Amount:</b></TableCell>
                                    <TableCell><b>Total Amount:</b></TableCell>
                                    <TableCell><b>Action:</b></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Dropdown
                                            error={formik.errors.item_id && formik.touched.item_id ? formik.errors.item_id : ""}
                                            // label="Item"
                                            placeholder="Item"
                                            options={itemData}
                                            getOptionLabel={option => option.item_name}
                                            value={itemData?.find((p) => p.id === formik.values.item_id)}
                                            onChange={(e, value) => {
                                                formik.setFieldValue('item_id', value?.id);
                                                formik.setFieldValue('item_name', value?.item_name);
                                                formik.setFieldValue('sales_price', value?.sales_price);
                                                formik.setFieldValue('gst_tax_rate', value?.gst_tax_rate);
                                                formik.setFieldValue('unit', value?.unit);

                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            type="number"
                                            // label="Rate"
                                            placeholder="Rate"
                                            value={formik.values?.sales_price}
                                            onChange={(e) => { formik.setFieldValue('sales_price', e.target.value) }}

                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // disabled
                                            type="string"
                                            // label="UOM"
                                            placeholder="UOM"
                                            value={formik.values?.unit}
                                            onChange={(e) => { formik.setFieldValue('unit', e.target.value) }}

                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            error={formik.errors.quantity && formik.touched.quantity ? formik.errors.quantity : ""}
                                            type="number"
                                            // label="Qty."
                                            placeholder="Qty."
                                            value={formik.values?.quantity}
                                            onChange={(e) => {
                                                formik.setFieldValue('quantity', e.target.value)
                                                const amount = formik.values?.sales_price * e.target.value;
                                                const gst_amount = amount * formik.values?.gst_tax_rate / 100;
                                                //  console.log(gst_amount);
                                                formik.setFieldValue('gst_amount', gst_amount)
                                                formik.setFieldValue('total', parseFloat(amount + gst_amount))
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // label="GST%"
                                            placeholder="GST%"
                                            value={formik.values?.gst_tax_rate}
                                            onChange={(e) => { formik.setFieldValue('gst_tax_rate', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // disabled
                                            type="number"
                                            // label="GST Amount"
                                            placeholder="GST Amount"
                                            value={formik.values?.gst_amount}
                                        //onChange={(e) => { setFieldValue('gst_amount', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // label="Total With GST"
                                            placeholder="Total With GST"
                                            value={formik.values?.total}
                                            onChange={(e) => { formik.setFieldValue('total', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <LoadingButton loading={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" color="success">
                                            {editData?.item_id ? "Update" : " Add"}
                                        </LoadingButton>
                                    </TableCell>

                                </TableRow>
                                {itemlist.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.item_name}</TableCell>
                                        <TableCell>{decimalNumber(row.sales_price)}</TableCell>
                                        <TableCell>{row.unit}</TableCell>
                                        <TableCell>{row.quantity} </TableCell>
                                        <TableCell>{row.gst_tax_rate}% </TableCell>
                                        <TableCell>{decimalNumber(row.gst_amount)}</TableCell>
                                        <TableCell>{decimalNumber(row.total)}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                                                <Button
                                                    onClick={() => {
                                                        handleListEdit(row.item_id);
                                                        // setEditData({})
                                                        // alert(row.item_id)
                                                    }}
                                                ><LxIcon name="FaRegEdit" type="fa" /></Button>
                                                <Button
                                                    onClick={() => {
                                                        setDeleteId({})
                                                        setDeleteId(row.item_id)
                                                        setOpenPopup(true)
                                                    }}
                                                    color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={3} sx={{
                                        fontWeight: "700",
                                        fontSize: 16,
                                        color: "#000000"
                                    }}>Net Amount: {sum}</TableCell>
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* <Card> */}
                    <Grid spacing={3} container item xs={12} md={12}>
                        <Grid item xs={3} md={3}>
                            <TextInput
                                type="string"
                                minRows={3}
                                multiline
                                label="Remarks"
                                value={Invoiceformik.values?.remarks}
                                onChange={(e) => { Invoiceformik.setFieldValue('remarks', e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <TextInput
                                type="string"
                                label="Advance Received*"
                                value={Invoiceformik.values?.advance_received}
                                onChange={(e) => { Invoiceformik.setFieldValue('advance_received', e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Dropdown
                                // label="Payment Method"
                                placeholder="Payment Method"
                                options={paymentMode}
                                getOptionLabel={option => option.payment_mode}
                                value={paymentMode?.find((p) => p.id === Invoiceformik.values.payment_mode_id) || null}
                                onChange={(e, value) => {
                                    Invoiceformik.setFieldValue('payment_mode_id', value?.id);

                                }}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <TextInput
                                type="string"
                                label="Payment Ref"
                                value={Invoiceformik.values?.payment_reference}
                                onChange={(e) => { Invoiceformik.setFieldValue('payment_reference', e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                    {/* </Card> */}
                </Grid>
                <Grid item xs={3} md={3}>
                    <Button onClick={() => { navigate('/invoice'); }} color="error" autoFocus variant="contained">Close</Button>
                    <LoadingButton
                        style={{ marginRight: 10, marginLeft: 30 }}
                        onClick={Invoiceformik.handleSubmit}
                        loading={Invoiceformik.isSubmitting}
                        color="success"
                        autoFocus
                        variant="contained">
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleListDelete(deleteId, setOpenPopup)}
            />
        </Stack >

    )
}
export default InvoiceForm;