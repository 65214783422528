import { atom } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
        isReset
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, JSON.stringify(newValue));
    });
};

export const layoutState = atom({
    key: 'layoutState',
    default: true,
    effects_UNSTABLE: [
        localStorageEffect("toggleMenu"),
    ],
});

export const loadingState = atom({
    key: 'loadingState',
    default: {
        type: "",
        status: false
    }
});


export const authState = atom({
    key: 'authState',
    default: null,
    effects: [
        localStorageEffect('auth-token'),
    ]
});

export const companyState = atom({
    key: 'companyState',
    default: []
});
export const projectState = atom({
    key: 'projectState',
    default: []
});
export const ProjectEditState = atom({
    key: 'ProjectEditState',
    default: {}
});

export const PaymentlisttState = atom({
    key: 'PaymentlisttState',
    default: []
});
export const ExpensesState = atom({
    key: 'ExpensesState',
    default: []
});
export const ExpensesTypeState = atom({
    key: 'ExpensesTypeState',
    default: []
});
export const ProfileState = atom({
    key: 'ProfileState',
    default: []
});
export const PaybleState = atom({
    key: 'PaybleState',
    default: []
});
export const PaymentModeState = atom({
    key: 'PaymentModeState',
    default: []
});
export const CategorieState = atom({
    key: 'CategorieState',
    default: []
});
export const ItemState = atom({
    key: 'ItemState',
    default: []
});
export const ItemEditState = atom({
    key: 'ItemEditState',
    default: []
});
export const InvoiceState = atom({
    key: 'InvoiceState',
    default: []
});
export const InvoiceDetailsState = atom({
    key: 'InvoiceDetailsState',
    default: []
});
export const InvoiceEditState = atom({
    key: 'InvoiceEditState',
    default: []
});
export const VendorState = atom({
    key: 'VendorState',
    default: []
});
export const VendorEditState = atom({
    key: 'VendorEditState',
    default: []
});
export const PurchaseState = atom({
    key: 'PurchaseState',
    default: []
});