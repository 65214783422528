import React, { useEffect } from "react";
import { Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Dropdown from "./Modules/Dropdown";
import TextInput from "./Modules/TextInput";
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import { getInvoiceData } from "./Actions/InvoiceAction";
import { useRecoilValue } from "recoil";
import { InvoiceState, VendorEditState } from "../Actions";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { VendorSubmit, getVendorEditData, handleVendorEdit } from "./Actions/VendorAction";
import { setRecoil } from "recoil-nexus";

const VendorForm = () => {
    const navigate = useNavigate();
    const invoiceData = useRecoilValue(InvoiceState);
    const vendorEdit = useRecoilValue(VendorEditState);
    const paymentMode = invoiceData?.payment_mode ? invoiceData?.payment_mode : []
    const StateData = invoiceData?.state ? invoiceData?.state : []
    let vendorId = (new URLSearchParams(window.location.search)).get("vendor-id")
    // console.log("dsadafaf", StateData);
    useEffect(() => {
        getInvoiceData()
        getVendorEditData(vendorId)
        vendorId === null && setRecoil(VendorEditState, {})
    }, [vendorId])

    const Vendorformik = useFormik({
        initialValues: {
            id: vendorId ? vendorId : '',
            primary_contact: vendorEdit?.primary_contact ? vendorEdit?.primary_contact : '',
            company_name: vendorEdit?.company_name ? vendorEdit?.company_name : '',
            vendor_name: vendorEdit?.vendor_name ? vendorEdit?.vendor_name : '',
            email: vendorEdit?.email ? vendorEdit?.email : '',
            mobile_number: vendorEdit?.mobile_number ? vendorEdit?.mobile_number : '',
            address: vendorEdit?.address ? vendorEdit?.address : '',
            state: vendorEdit?.state ? vendorEdit?.state : '',
            pin_code: vendorEdit?.pin_code ? vendorEdit?.pin_code : '',
            account_number: vendorEdit?.account_number ? vendorEdit?.account_number : '',
            ifsc_code: vendorEdit?.ifsc_code ? vendorEdit?.ifsc_code : '',
            bank_name: vendorEdit?.bank_name ? vendorEdit?.bank_name : '',
            branch_name: vendorEdit?.branch_name ? vendorEdit?.branch_name : '',
            pan_number: vendorEdit?.pan_number ? vendorEdit?.pan_number : '',
            opening_balance: vendorEdit?.opening_balance ? vendorEdit?.opening_balance : '',
            gst: vendorEdit?.gst ? vendorEdit?.gst : '',
            payment_term_id: vendorEdit?.payment_term_id ? vendorEdit?.payment_term_id : '',
            tds: vendorEdit?.tds ? vendorEdit?.tds : '',
            remarks: vendorEdit?.remarks ? vendorEdit?.remarks : ''

        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            primary_contact: Yup.number().required('primary_contact is required'),
            company_name: Yup.string().required('company_name is required'),
            vendor_name: Yup.string().required('vendor_name is required'),
            email: Yup.string().email().required('email is required'),
            mobile_number: Yup.number().required('mobile_number is required'),
            address: Yup.string().required('address is required'),
            state: Yup.string().required('state is required'),
            pin_code: Yup.string().required('Pincode is required'),
            account_number: Yup.number().required('account_number is required'),
            ifsc_code: Yup.string().required('ifsc_code is required'),
            bank_name: Yup.string().required('bank_name is required'),
            branch_name: Yup.string().required('branch_name is required'),
            pan_number: Yup.string().required('pan_number is required'),
            opening_balance: Yup.number().required('opening_balance is required'),
            gst: Yup.number().required('gst is required'),
            payment_term_id: Yup.string().required('payment_term_id is required'),
            tds: Yup.number().required('tds is required'),
            remarks: Yup.string().required('remarks is required'),
        }),
        onSubmit: (values, actions) => {
            if (values?.id) {
                handleVendorEdit(values, values?.id, actions, navigate)
            }
            else {
                VendorSubmit(values, actions, navigate)
            }
        },
    });

    return (
        <Stack spacing={2} direction="row">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={12}><h2>Add Vendor Details</h2></Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Primary Contact"
                        error={Vendorformik.errors.primary_contact && Vendorformik.touched.primary_contact ? Vendorformik.errors.primary_contact : ""}
                        type="string"
                        value={Vendorformik.values?.primary_contact}
                        onChange={(e) => { Vendorformik.setFieldValue('primary_contact', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Company Name"
                        error={Vendorformik.errors.company_name && Vendorformik.touched.company_name ? Vendorformik.errors.company_name : ""}
                        type="string"
                        value={Vendorformik.values?.company_name}
                        onChange={(e) => { Vendorformik.setFieldValue('company_name', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Vendor Name"
                        error={Vendorformik.errors.vendor_name && Vendorformik.touched.vendor_name ? Vendorformik.errors.vendor_name : ""}
                        type="string"
                        value={Vendorformik.values?.vendor_name}
                        onChange={(e) => { Vendorformik.setFieldValue('vendor_name', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        type="email"
                        label="Email"
                        error={Vendorformik.errors.email && Vendorformik.touched.email ? Vendorformik.errors.email : ""}
                        value={Vendorformik.values?.email}
                        onChange={(e) => { Vendorformik.setFieldValue('email', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        type="number"
                        label="Mobile Number"
                        error={Vendorformik.errors.mobile_number && Vendorformik.touched.mobile_number ? Vendorformik.errors.mobile_number : ""}
                        value={Vendorformik.values?.mobile_number}
                        onChange={(e) => { Vendorformik.setFieldValue('mobile_number', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Address"
                        error={Vendorformik.errors.address && Vendorformik.touched.address ? Vendorformik.errors.address : ""}
                        type="string"
                        value={Vendorformik.values?.address}
                        onChange={(e) => { Vendorformik.setFieldValue('address', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <Dropdown
                        error={Vendorformik.errors.state && Vendorformik.touched.state ? Vendorformik.errors.state : ""}
                        label="State"
                        placeholder="State"
                        options={StateData}
                        getOptionLabel={option => option.name}
                        value={StateData?.find((p) => p.name === Vendorformik.values.state) || null}
                        onChange={(e, value) => {
                            //   console.log(value);
                            Vendorformik.setFieldValue('state', value?.name);
                        }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Pin Code"
                        error={Vendorformik.errors.pin_code && Vendorformik.touched.pin_code ? Vendorformik.errors.pin_code : ""}
                        type="string"
                        value={Vendorformik.values?.pin_code}
                        onChange={(e) => { Vendorformik.setFieldValue('pin_code', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Account Number"
                        error={Vendorformik.errors.account_number && Vendorformik.touched.account_number ? Vendorformik.errors.account_number : ""}
                        type="number"
                        value={Vendorformik.values?.account_number}
                        onChange={(e) => { Vendorformik.setFieldValue('account_number', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="IFSC Code"
                        error={Vendorformik.errors.ifsc_code && Vendorformik.touched.ifsc_code ? Vendorformik.errors.ifsc_code : ""}
                        type="string"
                        value={Vendorformik.values?.ifsc_code}
                        onChange={(e) => { Vendorformik.setFieldValue('ifsc_code', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Bank Name"
                        error={Vendorformik.errors.bank_name && Vendorformik.touched.bank_name ? Vendorformik.errors.bank_name : ""}
                        type="string"
                        value={Vendorformik.values?.bank_name}
                        onChange={(e) => { Vendorformik.setFieldValue('bank_name', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Branch Name"
                        error={Vendorformik.errors.branch_name && Vendorformik.touched.branch_name ? Vendorformik.errors.branch_name : ""}
                        type="string"
                        value={Vendorformik.values?.branch_name}
                        onChange={(e) => { Vendorformik.setFieldValue('branch_name', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Pan Number"
                        error={Vendorformik.errors.pan_number && Vendorformik.touched.pan_number ? Vendorformik.errors.pan_number : ""}
                        type="string"
                        value={Vendorformik.values?.pan_number}
                        onChange={(e) => { Vendorformik.setFieldValue('pan_number', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Opening Balance"
                        error={Vendorformik.errors.opening_balance && Vendorformik.touched.opening_balance ? Vendorformik.errors.opening_balance : ""}
                        type="string"
                        value={Vendorformik.values?.opening_balance}
                        onChange={(e) => { Vendorformik.setFieldValue('opening_balance', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <Dropdown
                        label="GST%"
                        error={Vendorformik.errors.gst && Vendorformik.touched.gst ? Vendorformik.errors.gst : ""}
                        options={[{ id: '5', value: '5%' }, { id: '10', value: '10%' }, { id: '18', value: '18%' }, { id: '28', value: '28%' }]}
                        getOptionLabel={option => option.value}
                        value={[{ id: '5', value: '5%' }, { id: '10', value: '10%' }, { id: '18', value: '18%' }, { id: '28', value: '28%' }].find((p) => p.id === Vendorformik.values?.gst) || null}
                        onChange={(e, value) => {
                            Vendorformik.setFieldValue('gst', value?.id);
                            // console.log(value);
                        }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <Dropdown
                        error={Vendorformik.errors.payment_term_id && Vendorformik.touched.payment_term_id ? Vendorformik.errors.payment_term_id : ""}
                        label="Payment Terms"
                        options={paymentMode}
                        getOptionLabel={option => option.payment_mode}
                        value={paymentMode?.find((p) => p.id === Vendorformik.values.payment_term_id) || null}
                        onChange={(e, value) => {
                            Vendorformik.setFieldValue('payment_term_id', value?.id);

                        }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="TDS"
                        error={Vendorformik.errors.tds && Vendorformik.touched.tds ? Vendorformik.errors.tds : ""}
                        type="string"
                        value={Vendorformik.values?.tds}
                        onChange={(e) => { Vendorformik.setFieldValue('tds', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextInput
                        label="Remarks"
                        error={Vendorformik.errors.remarks && Vendorformik.touched.remarks ? Vendorformik.errors.remarks : ""}
                        type="string"
                        value={Vendorformik.values?.remarks}
                        onChange={(e) => { Vendorformik.setFieldValue('remarks', e.target.value) }}
                    />
                </Grid>

                <Grid item xs={3} md={3}>
                    <Button onClick={() => { navigate('/vendor'); }} color="error" autoFocus variant="contained">Close</Button>
                    <LoadingButton
                        style={{ marginRight: 10, marginLeft: 30 }}
                        onClick={Vendorformik.handleSubmit}
                        loading={Vendorformik.isSubmitting}
                        color="success"
                        autoFocus
                        variant="contained">
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
        </Stack>
    )
}
export default VendorForm;