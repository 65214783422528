import { setRecoil } from "recoil-nexus";
import AxiosApi from "../../Actions/AxiosApi";
import { toast } from 'react-toastify';
import { VendorEditState, VendorState } from "../../Actions";

export const getVendorDetails = () => {
    AxiosApi.get("vendor")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(VendorState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("Vendor-data", error);
        })
}
export const VendorSubmit = (values, actions, navigate) => {
    AxiosApi.post("vendor-add", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/vendor')
                getVendorDetails()
                toast.success(data.message);
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            console.log("Vendor Submit", error);
        })

}
export const getVendorEditData = (vendorId) => {
    AxiosApi.get(`vendor-get/${vendorId}`)
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(VendorEditState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("vendor Update", error);
        })
}
export const handleVendorEdit = (values,id, actions, navigate) => {
    AxiosApi.post(`vendor-edit/${id}`, values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/vendor')
                getVendorDetails()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("vendor Edit", error);
        })

}
export const handleVendorDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`vendor-delete/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getVendorDetails()
                toast.success(data.message);
                setOpenPopup(false)
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get vendor", error);
        })

}