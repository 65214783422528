import React from "react";
import { Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import { Stack } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import moment from 'moment';
import * as Yup from 'yup'
import { handleEditSubmit, submitdata } from "../components/Actions/ExpensesAction";
import { ExpensesTypeState } from "../Actions";
import { useRecoilValue } from "recoil";
import Grid from '@mui/material/Grid';
import CustomDialog from "./Modules/CustomDialog";
import Dropdown from "./Modules/Dropdown";
import DateInput from "./Modules/DateInput";
import TextInput from "./Modules/TextInput";

const ExpensesForm = (props) => {
    const { openAddModal, onClose, editData } = props;
    const expenseTypeData = useRecoilValue(ExpensesTypeState)
    const validationSchema = Yup.object().shape({
        expense_type_id: Yup.string()
            .required('Expense title is required'),
        paid_to: Yup.string()
            .required('This field is required'),
        paid_by: Yup.string()
            .required('This field is required'),
        amount: Yup.string()
            .required('Amount is required'),

        date: Yup.date()
            .required('Date is required'),

        note: Yup.string()
            .required('Note is required'),
    })
    // console.log(editData)
    return (
        <Stack spacing={2} direction="row">
            <Dialog
                open={openAddModal}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Formik
                    initialValues={{
                        title: editData?.title,
                        id: editData?.id,
                        expense_type_id: editData?.expense_type_id,
                        paid_to: editData?.paid_to,
                        paid_by: editData?.paid_by,
                        amount: editData?.amount,
                        // date: moment().format('YYYY-MM-DD'), {before edit date format}
                        date: editData?.date ? editData?.date : moment().format('YYYY-MM-DD'),
                        note: editData?.note,
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        //console.log(values);
                        if (values?.id) {
                            handleEditSubmit(values, actions, onClose)
                        } else {
                            submitdata(values, actions, onClose)
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => (
                        <>
                            {/* <DialogTitle id="alert-dialog-title">
                                {"Enter Your Expenses Details"}
                            </DialogTitle>
                            <DialogContent> */}
                            <CustomDialog
                                open={openAddModal}
                                onClose={onClose}
                                onSubmit={handleSubmit}
                                loading={isSubmitting}
                                title="Enter Your Expenses Details">
                                <Grid container spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item xs={6} md={16}>

                                        <Dropdown
                                            label="Expense Title"
                                            options={expenseTypeData}
                                            getOptionLabel={option => option.title}
                                            name="title"
                                            value={expenseTypeData?.find((e)=>e.id===values.expense_type_id)}
                                            onChange={(e, value) => {
                                                setFieldValue('expense_type_id',value.id)
                                                console.log('hhhh',value.id)
                                               // setFieldValue('title', value.title)
                                                // onChange={ handleChange }


                                            }}
                                        />
                                        {errors.expense_type_id && <span style={{ color: 'red' }}>{errors.expense_type_id && touched.expense_type_id ? errors.expense_type_id : ""}</span>}

                                        {/* <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Expense Title</InputLabel>
                                                <Select
                                                    label="transaction"
                                                    name="title"
                                                    value={values.expense_type_id}
                                                    onChange={(e) => {
                                                        setFieldValue('title', e.target.value)
                                                        setFieldValue('expense_type_id', e.target.value)

                                                    }}
                                                >
                                                    {expenseTypeData?.map((expensestype) =>
                                                        <MenuItem value={expensestype.id} >{expensestype.title}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box> */}
                                    </Grid>

                                    <Grid item xs={6} md={4}>
                                        <TextInput
                                            id="Paid to"
                                            name="paid_to"
                                            type="string"
                                            label="Paid to"
                                            value={values.paid_to}
                                            onChange={(e) => {
                                                setFieldValue('paid_to', e.target.value)
                                            }}
                                        />
                                        {/* <TextField id="Paid to"
                                            name="paid_to"
                                            label="Paid to"
                                            variant="outlined"
                                            type="string"
                                            fullWidth
                                            sx={{
                                                py: 1
                                            }}
                                            value={values.paid_to}
                                            onChange={(e) => {
                                                setFieldValue('paid_to', e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IoPersonSharp />
                                                    </InputAdornment>
                                                ),
                                            }}

                                        /> */}
                                        {errors.paid_to && <span style={{ color: 'red' }}>{errors.paid_to && touched.paid_to ? errors.paid_to : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <TextInput
                                            label="Paid by"
                                            type="string"
                                            value={values.paid_by}
                                            onChange={(e) => {
                                                setFieldValue('paid_by', e.target.value)
                                            }}
                                        />
                                        {errors.paid_by && <span style={{ color: 'red' }}>{errors.paid_by && touched.paid_by ? errors.paid_by : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={6}>

                                        <TextInput
                                            label=" Amount"
                                            type={"number"}
                                            value={values.amount}
                                            onChange={(e) => { setFieldValue('amount', e.target.value) }}
                                        />
                                        {errors.amount && <span style={{ color: 'red' }}>{errors.amount && touched.amount ? errors.amount : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <DateInput
                                            value={values.date}
                                            onChange={(date) => {
                                                //console.log(date) 
                                                setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                            }}
                                        />
                                        {/* <LocalizationProvider dateAdapter={AdapterMoment} >
                                            <DesktopDatePicker
                                                label="Date"
                                                name="date"
                                                inputFormat="DD-MM-yyyy"
                                                value={values.date}
                                                onChange={(date) => {
                                                    //console.log(date) 
                                                    setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider> */}
                                        {errors.date && <span style={{ color: 'red' }}>{errors.date && touched.date ? errors.date : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={16}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={5}
                                            placeholder="Note"
                                            style={{ width: '100%' }}
                                            name="note"
                                            value={values.note}
                                            onChange={(e) => { setFieldValue('note', e.target.value) }}
                                        />
                                        {errors.note && <span style={{ color: 'red' }}>{errors.note && touched.note ? errors.note : ""}</span>}
                                    </Grid>
                                </Grid>
                                {/* </DialogContent>
                            <DialogActions
                                style={{ marginRight: 20 }}
                            >
                                <Button onClick={onClose} color="error" autoFocus variant="outlined"
                                    sx={{
                                        width: 60,
                                        height: 40
                                    }}
                                >Close</Button>
                                <LoadingButton
                                    onClick={handleSubmit}
                                    color="success"
                                    autoFocus
                                    variant="contained"
                                    // sx={{
                                    //     width: 60,
                                    //     height: 40
                                    // }}
                                >
                                {values?.id ? 'Update' : 'Add'}
                                </LoadingButton>
                            </DialogActions> */}
                            </CustomDialog>
                        </>
                    )}
                </Formik>
            </Dialog>
        </Stack>
    )
}
export default ExpensesForm;