import React, { useState } from "react";
import axios from 'axios';
import CatImage from "../resources/images/businessman-logging-his-tablet1.jpg"
import TextField from '@mui/material/TextField';
import { Grid, Paper, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import welImage from "../resources/images/Welcome.png"
import { Formik } from 'formik';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { BASE_URL } from '../components/Actions/Api';
import { useNavigate } from 'react-router-dom';
export default function BasicTextFields() {

  // const [apiMessage, setapiMessage] = useState('')
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    email: Yup.string()
      .required('Email number is required'),
    password: Yup.string()
      .required('Password is required'),
  })

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }

  const submitdata = (values, actions) => {

    axios.post(`${BASE_URL}/register`, values)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          actions.resetForm()
          navigate('/login')
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (

    <Grid>
      <Stack
        className="body-img2"
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}

        sx={{ height: '100vh' }}>
        <Paper
          className="background1"
          elevation={10}
          sx={{
            height: 500,
            borderRadius: 5,
            backgroundColor: "#f7fafb"


          }}>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img
                alt="complex"
                src={CatImage}
                style={{
                  width: '100%',
                  height: 500,
                  borderRadius: 20
                }} />
            </Grid>
            <Grid item xs={6} direction="column">
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  password: ''
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  submitdata(values, actions)


                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      px: 5,
                      height: 500
                    }}
                  >
                    <img
                      alt="just"
                      src={welImage}
                      style={{
                        width: 200,

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    />
                    <h3>Welcome To  Sysm Technology</h3>
                    <TextField
                      id="outlined-basic"
                      name="name"
                      label="Enter Your name"
                      variant="outlined"
                      size='small'
                      fullWidth
                      value={values.name}
                      onChange={(e) => { setFieldValue('name', e.target.value) }}
                    />
                    {errors.name && <span style={{ color: 'red' }}>{errors.name && touched.name ? errors.name : ""}</span>}
                    <TextField
                      id="outlined-basic"
                      name="email"
                      label="Enter Your Email"
                      variant="outlined"
                      size='small'
                      type={"email"}
                      fullWidth
                      value={values.email}
                      onChange={(e) => { setFieldValue('email', e.target.value) }}
                    />
                    {errors.email && <span style={{ color: 'red' }}>{errors.email && touched.email ? errors.email : ""}</span>}
                    <TextField
                      id="outline-basic"
                      name="password"
                      label="Password"
                      variant="outlined"
                      size="small"
                      type={"password"}
                      fullWidth
                      value={values.password}
                      onChange={(e) => { setFieldValue('password', e.target.value) }}
                    />
                    {errors.password && <span style={{ color: 'red' }}>{errors.password && touched.password ? errors.password : ""}</span>}
                    <FormControlLabel
                      control={
                        <Checkbox defaultUnChecked />
                      }
                      label="I agree With Terms and Condition Apply"
                      onChange={checkboxHandler}
                    />
                    <Button variant="contained"
                      color='success'
                      sx={{
                        width: 200,
                        height: 40,
                        backgroundColor: "#2da897"

                      }}
                      disabled={!agree}
                      onClick={handleSubmit}
                    >Sign Up</Button>
                    <h3>Already User :<a href="../login">Log In</a></h3>
                  </Stack>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Grid >
  );
}