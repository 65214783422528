import React, { useState } from "react";
import { Button, ButtonGroup, Stack, TableFooter, TableHead } from '@mui/material';
import Grid from '@mui/material/Grid';
import Dropdown from "./Modules/Dropdown";
import TextInput from "./Modules/TextInput";
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import DateInput from "./Modules/DateInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as Yup from 'yup'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { decimalNumber } from "../resources/NumberFormat";
import { LxIcon } from "../resources/LxIcon";
import ConfirmDialog from "./DeleteDialog";
import { getInvoiceData } from "./Actions/InvoiceAction";
import { useRecoilValue } from "recoil";
import { InvoiceState, VendorState } from "../Actions";
import { useEffect } from "react";
import { getVendorDetails } from "./Actions/VendorAction";
import moment from 'moment';
import { PurchaseSubmit } from "./Actions/PurchaseAction";

const PurchaseForm = () => {
    useEffect(() => {
        getInvoiceData()
        getVendorDetails()
    }, [])
    const navigate = useNavigate();
    const VendorData = useRecoilValue(VendorState)
    const invoiceData = useRecoilValue(InvoiceState);
    const itemData = invoiceData?.item_data ? invoiceData?.item_data : []
    const paymentMode = invoiceData?.payment_mode ? invoiceData?.payment_mode : []
    // console.log(paymentMode);
    const [OpenPopup, setOpenPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    // const [Purchaseformik?.values?.item_list, setItemList] = useState([]);
    const [editData, setEditData] = useState({});
    const [image, setImage] = useState('')
    const [file, setFile] = useState('');
    const fileHandle = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0])
    }

    const valueModi = (values) => {
        let formData = new FormData();
        formData.append('id', values?.id);
        formData.append('vendor_id', values?.vendor_id);
        formData.append('deliver_to', values?.deliver_to);
        formData.append('purchase_order', values?.purchase_order);
        formData.append('reference', values?.reference);
        formData.append('date', moment(values?.date).format('YYYY-MM-DD'));
        formData.append('delivery_date', moment(values?.delivery_date).format('YYYY-MM-DD'));
        formData.append('payment_term_id', values?.payment_term_id);
        formData.append('shipment_preference', values?.shipment_preference);
        image && formData.append('upload_file', image);
        formData.append('remark', values?.remark);
        // formData.append('item_list', values?.item_list);
        Object.entries(values?.item_list).forEach(([key, value], index) => {
            formData.append(`item_list[${index + 1}][${key}]`, value);
        });
        // console.log(values?.item_list);
        // for (var pair of formData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
        return formData;
    }
    const Purchaseformik = useFormik({
        initialValues: {
            id: '',
            vendor_id: '',
            deliver_to: '',
            purchase_order: 'abcd',
            reference: '',
            date: moment().format('YYYY-MM-DD'),
            delivery_date: moment().format('YYYY-MM-DD'),
            payment_term_id: '',
            shipment_preference: '',
            remark: '',
            item_list: []
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            vendor_id: Yup.string().required('Vendor name is required'),
            deliver_to: Yup.string().required('Delivery address is required'),
            reference: Yup.string().required('Reference is required'),
            payment_term_id: Yup.string().required('payment_term_id is required'),
            shipment_preference: Yup.string().required('Shipment preference is required'),
            remark: Yup.string().required('Remark is required')
        }),
        onSubmit: (values, actions) => {
            console.log(values);
            PurchaseSubmit(valueModi(values), actions, navigate);
        },
    });

    const formik = useFormik({
        initialValues: {
            // item_id: editData?.item_id ? editData?.item_id : '',
            item_name: editData?.item_name ? editData?.item_name : '',
            sales_price: editData?.sales_price ? editData?.sales_price : '',
            gst_tax_rate: editData?.gst_tax_rate ? editData?.gst_tax_rate : '',
            quantity: editData?.quantity ? editData?.quantity : '',
            gst_amount: editData?.gst_amount ? editData?.gst_amount : '',
            unit: editData?.unit ? editData?.unit : '',
            total: editData?.total ? editData?.total : '',
            discount: editData?.discount ? editData?.discount : '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            item_id: Yup.string().required('Item is required'),
            quantity: Yup.string().required('Quantity is required'),
        }),
        onSubmit: (values, actions) => {
            // console.log(values);
            {
                if (Purchaseformik?.values?.item_list?.find((i) => i.item_id === values?.item_id)) {
                    toast.error("This item already exists!!")
                    actions?.setSubmitting(false)
                } else {
                    actions?.setSubmitting(false)
                    const newArr = [...Purchaseformik?.values?.item_list, values];
                    // setItemList(newArr)
                    actions.resetForm()
                    Purchaseformik.setFieldValue('item_list', newArr)
                    setEditData({})
                }
            }
        },
    });
    // console.log(Purchaseformik?.values?.item_list);
    const handleListDelete = (deleteId, setOpenPopup) => {
        if (Purchaseformik?.values?.item_list?.find((i) => i.item_id === deleteId)) {
            Purchaseformik?.setFieldValue('item_list', Purchaseformik?.values?.item_list?.filter((i) => i.item_id !== deleteId))
            setOpenPopup(false)
            // toast.success("Item delete successfully")
        } else {
            toast.error("Item Not found!!")
        }
    }
    const handleListEdit = (item_id) => {
        if (Purchaseformik?.values?.item_list?.find((i) => i.item_id === item_id)) {
            setEditData(Purchaseformik?.values?.item_list?.find((i) => i.item_id === item_id))
            Purchaseformik?.setFieldValue('item_list', Purchaseformik.values.item_list?.filter((i) => i.item_id !== item_id)) /*to delete list item*/
        } else {
            toast.error("Item Not found!!")
        }
    }

    let sum = decimalNumber(Purchaseformik.values.item_list?.reduce((a, b) => a + b.total, 0));

    return (
        <Stack spacing={2} direction="row">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={12} md={12}><h2>Add Purchase Details</h2></Grid>
                <Grid item xs={6} md={6}>
                    <Grid item xs={6} md={12}>
                        <Dropdown
                            error={Purchaseformik.errors.vendor_id && Purchaseformik.touched.vendor_id ? Purchaseformik.errors.vendor_id : ""}
                            label={Purchaseformik.errors.vendor_id && Purchaseformik.touched.vendor_id ? Purchaseformik.errors.vendor_id : "Vendor Name*"}
                            options={VendorData}
                            getOptionLabel={option => option.vendor_name}
                            value={VendorData?.find((p) => p.id === Purchaseformik.values.vendor_id) || null}
                            onChange={(e, value) => {
                                Purchaseformik.setFieldValue('vendor_id', value?.id);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={12} mt={2}>
                        <TextInput
                            label="Purchase Order*"
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        label={Purchaseformik.errors.deliver_to && Purchaseformik.touched.deliver_to ? Purchaseformik.errors.deliver_to : "Deliver To*"}
                        minRows={3}
                        multiline
                        error={Purchaseformik.errors.deliver_to && Purchaseformik.touched.deliver_to ? Purchaseformik.errors.deliver_to : ""}
                        value={Purchaseformik.values?.deliver_to}
                        onChange={(e) => { Purchaseformik.setFieldValue('deliver_to', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        label={Purchaseformik.errors.reference && Purchaseformik.touched.reference ? Purchaseformik.errors.reference : "Reference"}
                        error={Purchaseformik.errors.reference && Purchaseformik.touched.reference ? Purchaseformik.errors.reference : ""}
                        value={Purchaseformik.values?.reference}
                        onChange={(e) => { Purchaseformik.setFieldValue('reference', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DateInput
                        label="Date"
                        value={Purchaseformik.values.date}
                        onChange={(date) => {
                            Purchaseformik.setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DateInput
                        label="Expected Delivery Date"
                        value={Purchaseformik.values.delivery_date}
                        onChange={(date) => {
                            Purchaseformik.setFieldValue('delivery_date', moment(date).format('YYYY-MM-DD'))
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <Dropdown
                        error={Purchaseformik.errors.payment_term_id && Purchaseformik.touched.payment_term_id ? Purchaseformik.errors.payment_term_id : ""}
                        label={Purchaseformik.errors.payment_term_id && Purchaseformik.touched.payment_term_id ? Purchaseformik.errors.payment_term_id : "Payment Terms"}
                        options={paymentMode}
                        getOptionLabel={option => option.payment_mode}
                        value={paymentMode?.find((p) => p.id === Purchaseformik.values.payment_term_id) || null}
                        onChange={(e, value) => {
                            Purchaseformik.setFieldValue('payment_term_id', value?.id);

                        }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput
                        type="text"
                        label={Purchaseformik.errors.shipment_preference && Purchaseformik.touched.shipment_preference ? Purchaseformik.errors.shipment_preference : "Shipment Preference "}
                        error={Purchaseformik.errors.shipment_preference && Purchaseformik.touched.shipment_preference ? Purchaseformik.errors.shipment_preference : ""}
                        value={Purchaseformik.values?.shipment_preference}
                        onChange={(e) => { Purchaseformik.setFieldValue('shipment_preference', e.target.value) }}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextInput class="file-input" type="file" label="Upload File" onChange={fileHandle} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} sx={{
                        "& .MuiTableCell-root": {
                            p: .5
                        }
                    }}>
                        <Table size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Item Name:</b></TableCell>
                                    <TableCell><b>Rate:</b></TableCell>
                                    <TableCell><b>UOM:</b></TableCell>
                                    <TableCell><b>Quantity:</b></TableCell>
                                    <TableCell><b>GST%</b></TableCell>
                                    <TableCell><b>GST Amount:</b></TableCell>
                                    <TableCell><b>Total Amount:</b></TableCell>
                                    <TableCell><b>Action:</b></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Dropdown
                                            error={formik.errors.item_id && formik.touched.item_id ? formik.errors.item_id : ""}
                                            // label="Item"
                                            placeholder="Item"
                                            options={itemData}
                                            getOptionLabel={option => option.item_name}
                                            value={itemData?.find((p) => p.id === formik.values.item_id)}
                                            onChange={(e, value) => {
                                                formik.setFieldValue('item_id', value?.id);
                                                formik.setFieldValue('item_name', value?.item_name);
                                                formik.setFieldValue('sales_price', value?.sales_price);
                                                formik.setFieldValue('gst_tax_rate', value?.gst_tax_rate);
                                                formik.setFieldValue('unit', value?.unit);

                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            type="number"
                                            // label="Rate"
                                            placeholder="Rate"
                                            value={formik.values?.sales_price}
                                            onChange={(e) => { formik.setFieldValue('sales_price', e.target.value) }}

                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // disabled
                                            type="string"
                                            // label="UOM"
                                            placeholder="UOM"
                                            value={formik.values?.unit}
                                            onChange={(e) => { formik.setFieldValue('unit', e.target.value) }}

                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            error={formik.errors.quantity && formik.touched.quantity ? formik.errors.quantity : ""}
                                            type="number"
                                            // label="Qty."
                                            placeholder="Qty."
                                            value={formik.values?.quantity}
                                            onChange={(e) => {
                                                formik.setFieldValue('quantity', e.target.value)
                                                const amount = formik.values?.sales_price * e.target.value;
                                                const gst_amount = amount * formik.values?.gst_tax_rate / 100;
                                                //  console.log(gst_amount);
                                                formik.setFieldValue('gst_amount', gst_amount)
                                                formik.setFieldValue('total', parseFloat(amount + gst_amount))
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // label="GST%"
                                            placeholder="GST%"
                                            value={formik.values?.gst_tax_rate}
                                            onChange={(e) => { formik.setFieldValue('gst_tax_rate', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // disabled
                                            type="number"
                                            // label="GST Amount"
                                            placeholder="GST Amount"
                                            value={formik.values?.gst_amount}
                                        //onChange={(e) => { setFieldValue('gst_amount', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            // label="Total With GST"
                                            placeholder="Total With GST"
                                            value={formik.values?.total}
                                            onChange={(e) => { formik.setFieldValue('total', e.target.value) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <LoadingButton loading={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" color="success">
                                            {editData?.item_id ? "Update" : " Add"}
                                        </LoadingButton>
                                    </TableCell>

                                </TableRow>
                                {Purchaseformik?.values?.item_list.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.item_name}</TableCell>
                                        <TableCell>{decimalNumber(row.sales_price)}</TableCell>
                                        <TableCell>{row.unit}</TableCell>
                                        <TableCell>{row.quantity} </TableCell>
                                        <TableCell>{row.gst_tax_rate}% </TableCell>
                                        <TableCell>{decimalNumber(row.gst_amount)}</TableCell>
                                        <TableCell>{decimalNumber(row.total)}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                                                <Button
                                                    onClick={() => {
                                                        handleListEdit(row.item_id);
                                                        // setEditData({})
                                                        // alert(row.item_id)
                                                    }}
                                                ><LxIcon name="FaRegEdit" type="fa" /></Button>
                                                <Button
                                                    onClick={() => {
                                                        setDeleteId({})
                                                        setDeleteId(row.item_id)
                                                        setOpenPopup(true)
                                                    }}
                                                    color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                {/* <TableRow> */}
                                <TableRow colSpan={3} sx={{
                                    fontWeight: "700",
                                    fontSize: 16,
                                    color: "#000000"
                                }}>
                                    Total Amount:
                                    {sum}
                                </TableRow>
                                <TableRow colSpan={3} sx={{
                                    fontWeight: "700",
                                    fontSize: 16,
                                    color: "#000000"
                                }}>
                                    {/* <Grid item xs={3} md={3}> */}
                                    <TextInput type="number" label="Discount"
                                        value={formik.values?.discount}
                                        onChange={(e) => {
                                            formik.setFieldValue('discount', e.target.value)
                                        }}
                                    />
                                    {/* </Grid> */}
                                </TableRow>

                                <TableRow colSpan={3} sx={{
                                    fontWeight: "700",
                                    fontSize: 16,
                                    color: "#000000"
                                }}>
                                    Net Amount:
                                    {decimalNumber(sum - Number(formik.values?.discount)) ? decimalNumber(sum - Number(formik.values?.discount)) :
                                        sum}
                                </TableRow>
                                {/* </TableRow> */}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* <Card> */}
                    <Grid spacing={3} container item xs={12} md={12}>
                        <Grid item xs={3} md={3}>
                            <TextInput
                                minRows={3}
                                multiline
                                type="text"
                                label={Purchaseformik.errors.remark && Purchaseformik.touched.remark ? Purchaseformik.errors.remark : "Remarks"}
                                error={Purchaseformik.errors.remark && Purchaseformik.touched.remark ? Purchaseformik.errors.remark : ""}
                                value={Purchaseformik.values?.remark}
                                onChange={(e) => { Purchaseformik.setFieldValue('remark', e.target.value) }}
                            />
                        </Grid>
                        {/* <Grid item xs={3} md={3}>
                            {file ? <>
                                <ClearIcon onClick={(e) => setFile('')} />
                                <img alt='imgview' style={{ height: '300px', width: '300px', paddingLeft: '50px' }} src={file} /></> :
                                // ItemEditDetails?.image &&
                                <img alt='imgview' style={{ height: '300px', width: '300px', paddingLeft: '50px' }}
                                // src={ItemEditDetails?.image} 
                                />}
                        </Grid> */}
                    </Grid>
                    {/* </Card> */}
                </Grid>
                <Grid item xs={3} md={3}>
                    <Button onClick={() => { navigate('/purchase'); }} color="error" autoFocus variant="contained">Close</Button>
                    <LoadingButton
                        style={{ marginRight: 10, marginLeft: 30 }}
                        onClick={Purchaseformik.handleSubmit}
                        loading={Purchaseformik.isSubmitting}
                        color="success"
                        autoFocus
                        variant="contained">
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleListDelete(deleteId, setOpenPopup)}
            />
        </Stack>
    )
}
export default PurchaseForm;