import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import { PaymentModeState } from "../../Actions";
import { useRecoilValue } from "recoil";
import CustomTable from "../../components/Modules/CustomTable";
import PaymentModeForm from "../../components/PaymentModeForm";
import { getPaymentModeData, handlePaymentModeDelete } from "../../components/Actions/PaymentModeAction";

export default function PaymentMode() {
    const paymentModeData = useRecoilValue(PaymentModeState);
    //  console.log(paymentModeData?.paymentMode);
    const [editData, setEditData] = useState({});
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [DeleteId, setDeleteId] = useState('');
    useEffect(() => {
        getPaymentModeData()
    }, [])
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Payment Type',
            selector: row => row.payment_type_info?.title,
            sortable: true,
            width: "20%"
        }, {
            name: 'Payment Mode',
            selector: row => row.payment_mode,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Payble Accounts',
            selector: row => row.payble_account_info?.name,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        setEditData(paymentModeData?.paymentMode.find((a) => a.id === row.id))
                        setOpenAddModal(true)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "15%"
        },
    ]


    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                setEditData({})
                setOpenAddModal(true)
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add Payment Mode
        </Button>
        <PaymentModeForm
            onClose={() => setOpenAddModal(false)}
            open={openAddModal}
            editData={editData}
        />
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = paymentModeData?.paymentMode?.filter(item => (
        item?.payment_mode.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.payble_account_info?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.payment_type_info?.title.toLowerCase().includes(searchInput.toLowerCase())
    ));
    return (
        <>

            <CustomTable
                title='Payment Mode Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
                subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handlePaymentModeDelete(DeleteId, setOpenPopup)}
            />

        </>
    );
}