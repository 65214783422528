import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import AxiosApi from '../../Actions/AxiosApi';
import { PurchaseState } from '../../Actions';

export const getPurchaseData = () => {
    AxiosApi.get("purchase")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(PurchaseState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("Purchase", error);
        })
}

export const PurchaseSubmit = (values, actions, navigate) => {
    AxiosApi.post("purchase-add", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                navigate('/purchase')
                getPurchaseData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            console.log("Purchase Submit", error);
        })

}
export const handlePurchaseDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`purchase-delete/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getPurchaseData()
                toast.success(data.message);
                setOpenPopup(false)
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("purchase", error);
        })

}