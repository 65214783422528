import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import { useRecoilValue } from "recoil";
import CustomTable from "../../components/Modules/CustomTable";
import { useNavigate } from 'react-router-dom';
import { getItemData, handleItemDelete } from "../../components/Actions/ItemAction";
import { ItemEditState, ItemState } from "../../Actions";
import moment from "moment";
import { resetRecoil } from "recoil-nexus";
import noImage from '../../resources/images/download.jpg'
const Items = () => {
    const ItemDataList = useRecoilValue(ItemState)
    // console.log(ItemDataList);
    const navigate = useNavigate();
    // const [editData, setEditData] = useState({});
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [DeleteId, setDeleteId] = useState('');
    useEffect(() => {
        getItemData()
    }, [])

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "7%"
        },
        {
            name: 'Product/Service',
            selector: row => row.taxonomy === 'S' ? 'Service' : 'Product' ,
            sortable: true,
            width: "8%"
        }, {
            name: 'Date',
            selector: row => moment(row.date).format('DD-MM-YYYY'),
            sortable: true,
            width: "10%"
        },
          {
            name: 'image',
            selector: row => row.image ? <img style={{borderRadius:20}} width={40} height={40} src={row.image} alt=""/> :
            <img style={{borderRadius:20}} width={40} height={40} src={noImage} alt=""/>,
            sortable: true,
            width: "7%"
        },
        {
            name: 'Item Name',
            selector: row => row.item_name,
            sortable: true,
            width: "10%"
        },

        {
            name: 'item_code',
            selector: row => row.item_code,
            sortable: true,
            width: "10%"
        },
        // {
        //     name: 'Item Description',
        //     selector: row => row.item_description,
        //     sortable: true,
        //     width: "10%"
        // },

        {
            name: 'HSN Code',
            selector: row => row.hsn_code,
            sortable: true,
            width: "10%"
        },

        {
            name: 'GST %',
            selector: row => `${row.gst_tax_rate}%`,
            sortable: true,
            width: "7%"
        },
        {
            name: 'Unit',
            selector: row => row.unit,
            sortable: true,
            width: "10%"
        },
        {
            name: 'MRP',
            selector: row => row.mrp,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        // navigate(`/item-edit/${row?.id}`)
                        navigate(`/item-add?item-id=${row?.id}`)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "12%"
        }
    ]


    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                navigate('/item-add');
                resetRecoil(ItemEditState) 
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add Item
        </Button>
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = ItemDataList?.filter(item => (
        item?.item_name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.taxonomy?.toLowerCase().includes(searchInput.toLowerCase())
    ));
    return (
        <>

            <CustomTable
                title='Item Details'
                columns={columns}
                data={filteredItems}
                actions={actions}
                subHeaderComponent={searchComponent}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleItemDelete(DeleteId, setOpenPopup)}
            />

        </>
    );
}
export default Items;