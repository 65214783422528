
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { PaymentlisttState } from '../../Actions';
import AxiosApi from '../../Actions/AxiosApi';

export const getPaymentData = () => {
  AxiosApi.get("payment")
    .then(function ({ data }) {
      if (data.status) {
        setRecoil(PaymentlisttState, data.data)
        // setRecoil(PaymentModeState,data.data?.payment_mode_info)
       //  console.log(data.data);
      }
    })
    .catch(error => {
      console.log("expenses", error);
    })
}

export const submitdata = (values, actions, onClose) => {
  AxiosApi.post("payment", values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        getPaymentData()
        toast.success(data.message);
      }
    })
    .catch(error => {
      console.log("payment", error);
    })

}

export const handlePaymentDelete = (id, setOpenPopup) => {
  AxiosApi.delete(`payment/${id}`)
    .then(function ({ data }) {
      if (data.status) {
        getPaymentData()
        toast.success(data.message);
        setOpenPopup(false);
      } else {
        toast.error(data.message);
      }
    })
    .catch(error => {
      console.log("Get Payble", error);
    })

}

// export const handlePaymentDelete = (id, setOpenPopup) => {
//   const dataToken = JSON.parse(localStorage.getItem('token'));

//   axios.delete(`${BASE_URL}/payment/${id}`,
//     {
//       headers: {
//         'Authorization': `Bearer ${dataToken}`
//       }
//     })
//     .then(function (response) {
//       if (response.data.status) {
//         toast.success(response.data.message);
//         console.log(response.data.data)
//         setRecoil(PaymentlisttState, response.data.data)
//         setOpenPopup(false)
//       } else {
//         toast.error(response.data.message);
//       }
//       // console.log(response.data.status)

//     })
// }

