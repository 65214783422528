import { TextField } from "@mui/material"
import React from "react"

const TextInput = (props) => {
    return (
        <TextField
            size="small"
            fullWidth
            {...props} />
    )
}

export default TextInput;