import React from "react";
import moment from 'moment';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from "@mui/material/Box";
import { submitdata } from "../components/Actions/PaymentActions";
import { companyState, PaymentlisttState } from "../Actions";
import { projectState } from "../Actions";
import { useRecoilValue } from "recoil";
import CustomDialog from "./Modules/CustomDialog";
import Dropdown from "./Modules/Dropdown";
import TextInput from "./Modules/TextInput";
import DateInput from "./Modules/DateInput";

const PaymentForm = (props) => {
    const { editData, onClose, openAddModal } = props
    const companyData = useRecoilValue(companyState)
    const paymentlist = useRecoilValue(PaymentlisttState);  
    const paymentModeData =  paymentlist?.payment_mode_info;
    // console.log(paymentModeData);
    const ProjectData = useRecoilValue(projectState)
    const validationSchema = Yup.object().shape({
        project_id: Yup.string().nullable()
            .required('Project name is required'),
        company_id: Yup.string()
            .required('Company name is required'),
        payment_type: Yup.string()
            .required('Transaction type is required'),
        payment_mode_id: Yup.string()
            .required('Payment type is required'),
        payment_account: Yup.string()
            .required('Account number is required'),
        amount: Yup.string()
            .required('Amount is required'),
        date: Yup.string()
            .required('Date is required'),
        note: Yup.string()
            .required('Note is required'),
    })
    return (
        <Dialog
            onClose={onClose}
            open={openAddModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    payment_type: editData?.payment_type,
                    company_id: editData?.company_id,
                    project_id: editData?.project_id,
                    payment_mode_id: editData?.payment_mode_id,
                    payment_account: editData?.payment_account ? editData?.payment_account : '',
                    amount: editData?.amount,
                    date: editData?.date ? editData?.date : moment().format('YYYY-MM-DD'),
                    note: editData?.note
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    console.log(values)
                    submitdata(values, actions, onClose)

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    //           <DialogTitle id="alert-dialog-title">
                    //     {"Enter Your Expense Details"}
                    // </DialogTitle>
                    //     <DialogContent>
                    <>
                        <CustomDialog
                            open={openAddModal}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            title="Enter Your Payment Details"
                        >
                            <Stack
                                spacing={2}
                            >
                                <Box sx={{ minWidth: 120 }}>
                                    <Dropdown
                                        label="Project name"
                                        options={ProjectData}
                                        getOptionLabel={option => option.p_name}
                                        value={ProjectData?.find((p) => p.id === values.project_id)}
                                        onChange={(e, value) => {
                                            setFieldValue('project_id', value.id)
                                            // setFieldValue('project_id', e.target.value)

                                        }}
                                    />
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Project name</InputLabel>
                                        <Select
                                            label="transaction"
                                            name="project_id"
                                            value={values.project_id}
                                            onChange={(e) => {
                                                setFieldValue('project_id', e.target.value)

                                            }}
                                        >
                                            {ProjectData?.map((project, index) =>
                                                <MenuItem key={index} value={project.id} >{project.p_name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl> */}
                                </Box>
                                {errors.project_id && <span style={{ color: 'red' }}>{errors.project_id && touched.project_id ? errors.project_id : ""}</span>}
                                <Box sx={{ minWidth: 120 }}>
                                    <Dropdown
                                        label="Company name"
                                        options={companyData}
                                        getOptionLabel={option => option.c_name}
                                        value={companyData?.find((p) => p.id === values.company_id)}
                                        onChange={(e, value) => {
                                            console.log(value)
                                            setFieldValue('company_id', value?.id);

                                        }}
                                    />
                                </Box>
                                {errors.company_id && <span style={{ color: 'red' }}>{errors.company_id && touched.company_id ? errors.company_id : ""}</span>}

                                <Box sx={{ minWidth: 120 }}>
                                    <Dropdown
                                        label="Transaction Type"
                                        options={[{ id: 'C', value: 'Credit' }, { id: 'D', value: 'Debit' }]}
                                        getOptionLabel={option => option.value}
                                        value={[{ id: 'C', value: 'Credit' }, { id: 'D', value: 'Debit' }].find((p) => p.id === values.payment_type)}
                                        onChange={(e, value) => {
                                            setFieldValue('payment_type', value?.id);
                                            // console.log(value);
                                        }}
                                    />
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                                        <Select
                                            label="transaction"
                                            name="payment_type"
                                            value={values.payment_type}
                                            onChange={(e) => { setFieldValue('payment_type', e.target.value) }}
                                        >
                                            <MenuItem value={"C"} >Credit</MenuItem>
                                            <MenuItem value={"D"}>Debit</MenuItem>

                                        </Select>
                                    </FormControl> */}
                                </Box>
                                {errors.payment_type && <span style={{ color: 'red' }}>{errors.payment_type && touched.payment_type ? errors.payment_type : ""}</span>}
                                <Box sx={{ minWidth: 120 }}>
                                    <Dropdown
                                        label="Payment Type"
                                        options={paymentModeData}
                                        getOptionLabel={option => option.payment_mode}
                                       value={paymentModeData?.find((p) => p.id === values.payment_mode_id)}
                                        onChange={(e, value) => {
                                            setFieldValue('payment_mode_id', value?.id);
                                            // console.log(value);
                                        }}
                                    />
                                </Box>
                                {errors.payment_mode_id && <span style={{ color: 'red' }}>{errors.payment_mode_id && touched.payment_mode_id ? errors.payment_mode_id : ""}</span>}
                                <TextInput
                                    label=" Account Number"
                                    type={"number"}
                                    value={values.payment_account}
                                    onChange={(e) => { setFieldValue('payment_account', e.target.value) }}
                                />
                                {/* <TextField id="Amount Number"
                                    label=" Account Number"
                                    name="payment_account"
                                    type={"number"}
                                    value={values.payment_account}
                                    onChange={(e) => { setFieldValue('payment_account', e.target.value) }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BiRupee />
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}
                                {errors.payment_account && <span style={{ color: 'red' }}>{errors.payment_account && touched.payment_account ? errors.payment_account : ""}</span>}

                                <TextInput
                                    label=" Amount"
                                    type={"number"}
                                    value={values.amount}
                                    onChange={(e) => { setFieldValue('amount', e.target.value) }}

                                />
                                {errors.amount && <span style={{ color: 'red' }}>{errors.amount && touched.amount ? errors.amount : ""}</span>}
                                <DateInput
                                    label="Date"
                                    name="date"
                                    value={values.date}
                                    onChange={(date) => {
                                        //console.log(date) 
                                        setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                    }}
                                />
                                {errors.date && <span style={{ color: 'red' }}>{errors.date && touched.date ? errors.date : ""}</span>}
                                {/* <LocalizationProvider dateAdapter={AdapterMoment} >
                                    <DesktopDatePicker
                                        label="Date"
                                        name="date"
                                        value={values.date}
                                        onChange={(date) => {
                                            //console.log(date) 
                                            setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}

                                <TextareaAutosize
                                    name="note"
                                    value={values.note}
                                    onChange={(e) => { setFieldValue('note', e.target.value) }}
                                    aria-label="minimum height"
                                    minRows={5}
                                    placeholder="Status"
                                    style={{ width: 548 }}
                                />
                                {errors.note && <span style={{ color: 'red' }}>{errors.note && touched.note ? errors.note : ""}</span>}
                            </Stack>
                        </CustomDialog>
                    </>
                    // </DialogContent>
                    // <DialogActions>
                    //     <Button onClick={onClose} color="error" variant='contained'>Close</Button>
                    //     <Button onClick={handleSubmit} autoFocus color='success' variant='contained' >
                    //         {values?.id ? 'Update' : 'Add'}
                    //     </Button>
                    // </DialogActions>


                )}
            </Formik>
        </Dialog>
    )
}
export default PaymentForm;


//8250777864