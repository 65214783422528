import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { BsPlusLg } from "react-icons/bs";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import TextInput from './Modules/TextInput';
import Dropdown from './Modules/Dropdown';
import DateInput from './Modules/DateInput';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup'
import moment from 'moment';
import { CategorieState, ItemEditState } from '../Actions';
import { useRecoilValue } from 'recoil';
import { getEditData, handleItemEdit, ItemSubmit } from './Actions/ItemAction';
import { getCategoriesData } from './Actions/CategoriesAction';

const ItemsForm = () => {
    let itemid = (new URLSearchParams(window.location.search)).get("item-id")
    // console.log(itemid);
    const ItemEditDetails = useRecoilValue(ItemEditState);
    const CategorieData = useRecoilValue(CategorieState);
    const [image,setImage] = useState('')
    const navigate = useNavigate();
    const [file, setFile] = useState('');
    const ImageHandle = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0])
    }
    const validationSchema = Yup.object().shape({
        taxonomy: Yup.string()
            .required('Taxonomy is required'),
        date: Yup.string()
            .required('Date is required'),
        // image: Yup.string()
        //     .required('Image is required'),
        item_name: Yup.string()
            .required('Item name is required'),
        category_id: Yup.string()
            .required('Category is required'),
        item_code: Yup.string()
            .required('Item Code is required'),
        barcode: Yup.string()
            .required('Barcode is required'),
        item_description: Yup.string()
            .required('Item Description is required'),
        unit: Yup.string()
            .required('Unit is required'),
        mrp: Yup.string()
            .required('MRP is required'),
        purchasing_price: Yup.string()
            .required('Purchasing price is required'),
        gst_tax_rate: Yup.string()
            .required('GST TAX% is required'),
        hsn_code: Yup.string()
            .required('HSN CODE is required'),
        opening_stock: Yup.string()
            .required('Opening Stock is required'),
        sales_price: Yup.string()
            .required('Sales Price is required'),
    })
    const valueModi = (values) =>{
        let formData = new FormData();
        formData.append('id', values?.id);
        formData.append('taxonomy', values?.taxonomy);
        image && formData.append('image',image);
        formData.append('date', moment(values?.date).format('YYYY-MM-DD'));
        formData.append('item_name', values?.item_name);
        formData.append('category_id', values?.category_id);
        formData.append('item_code', values?.item_code);
        formData.append('barcode', values?.barcode);
        formData.append('item_description', values?.item_description);
        formData.append('unit', values?.unit);
        formData.append('mrp', values?.mrp);
        formData.append('purchasing_price', values?.purchasing_price);
        formData.append('gst_tax_rate', values?.gst_tax_rate);
        formData.append('hsn_code', values?.hsn_code);
        formData.append('opening_stock', values?.opening_stock);
        formData.append('sales_price', values?.sales_price);
        return formData;
    }
    useEffect(() => {
        getCategoriesData()
        // console.log(itemid);
        getEditData(itemid)
    }, [itemid])

    return (
        <Stack spacing={2} direction="row">
            <Formik
                initialValues={{
                    id: itemid,
                    taxonomy: ItemEditDetails?.taxonomy,
                    date: ItemEditDetails?.date ? ItemEditDetails?.date : new Date(),
                    item_name: ItemEditDetails?.item_name,
                    category_id: ItemEditDetails?.category_id,
                    item_code: ItemEditDetails?.item_code,
                    barcode: ItemEditDetails?.barcode,
                    item_description: ItemEditDetails?.item_description,
                    unit: ItemEditDetails?.unit,
                    mrp: ItemEditDetails?.mrp,
                    purchasing_price: ItemEditDetails?.purchasing_price,
                    gst_tax_rate: ItemEditDetails?.gst_tax_rate,
                    hsn_code: ItemEditDetails?.hsn_code,
                    opening_stock: ItemEditDetails?.opening_stock,
                    sales_price: ItemEditDetails?.sales_price

                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    if (values?.id) {
                        handleItemEdit(valueModi(values),values?.id, actions, navigate)
                    } else {
                        ItemSubmit(valueModi(values), actions, navigate)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                }) => (
                    <>
                        <Grid container spacing={1}>
                        <Grid item xs={12} md={12}><h2>Add Item Details</h2></Grid>
                            <Grid container item md={8} spacing={2}>
                                <Grid item xs={3}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={values.taxonomy || null}
                                        onChange={(e) => {
                                            //console.log(e?.target?.value);
                                            setFieldValue("taxonomy", e?.target?.value);
                                        }}
                                    >
                                        <FormControlLabel value="P" control={<Radio />} label="Product" />
                                        <FormControlLabel value="S" control={<Radio />} label="Service" />
                                    </RadioGroup>
                                    {errors.taxonomy && <span style={{ color: 'red' }}>{errors.taxonomy && touched.taxonomy ? errors.taxonomy : ""}</span>}
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        label="Date"
                                        value={values.date}
                                        onChange={(date) => {
                                            setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                        }}
                                    />
                                    {/* {errors.date && <span style={{ color: 'red' }}>{errors.date && touched.date ? errors.date : ""}</span>} */}
                                </Grid>
                                <Grid item xs={6}>
                                <TextInput class="file-input" type="file" onChange={ImageHandle} />
                                    {/* <Button variant="contained" component="label">Upload Product Image
                                        <BsPlusLg />
                                        <input hidden accept="image/*" multiple type="file" onChange={ImageHandle} />
                                    </Button> */}
                                    {/* {errors.image && <span style={{ color: 'red' }}>{errors.image && touched.image ? errors.image : ""}</span>} */}
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        error={errors.item_name && touched.item_name ? errors.item_name : ""}
                                        label="Item Name"
                                        type="string"
                                        value={values.item_name || null}
                                        onChange={(e) => { setFieldValue('item_name', e.target.value) }}
                                    />
                                    {/* {errors.item_name && <span style={{ color: 'red' }}>{errors.item_name && touched.item_name ? errors.item_name : ""}</span>} */}
                                </Grid>

                                <Grid item xs={4}>
                                    <Dropdown
                                        error={errors.category_id && touched.category_id ? errors.category_id : ""}
                                        label="Category"
                                        options={CategorieData}
                                        getOptionLabel={option => option.title}
                                        value={CategorieData?.find((p) => p.id === values.category_id) || null}
                                        onChange={(e, value) => {
                                            console.log(value)
                                            setFieldValue('category_id', value?.id);

                                        }}
                                    />
                                    {/* {errors.category_id && <span style={{ color: 'red' }}>{errors.category_id && touched.category_id ? errors.category_id : ""}</span>} */}
                                </Grid>

                                <Grid item xs={4}>
                                    <TextInput
                                        error={errors.item_code && touched.item_code ? errors.item_code : ""}
                                        label="Item Code"
                                        type="string"
                                        value={values.item_code || null}
                                        onChange={(e) => { setFieldValue('item_code', e.target.value) }}
                                    />
                                    {/* {errors.item_code && <span style={{ color: 'red' }}>{errors.item_code && touched.item_code ? errors.item_code : ""}</span>} */}
                                </Grid>

                                <Grid item xs={4}>
                                    <TextInput
                                        error={errors.barcode && touched.barcode ? errors.barcode : ""}
                                        label="Generate Barcode"
                                        type="string"
                                        value={values.barcode || null}
                                        onChange={(e) => { setFieldValue('barcode', e.target.value) }}
                                    />
                                    {/* {errors.barcode && <span style={{ color: 'red' }}>{errors.barcode && touched.barcode ? errors.barcode : ""}</span>} */}
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput id="filled-multiline-static"
                                        error={errors.item_description && touched.item_description ? errors.item_description : ""}
                                        label="Item Description"
                                        multiline
                                        rows={4}
                                        type="string"
                                        value={values.item_description || null}
                                        onChange={(e) => { setFieldValue('item_description', e.target.value) }}
                                    />
                                    {/* {errors.item_description && <span style={{ color: 'red' }}>{errors.item_description && touched.item_description ? errors.item_description : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextInput
                                        error={errors.unit && touched.unit ? errors.unit : ""}
                                        label="Unit"
                                        type="string"
                                        value={values.unit || null}
                                        onChange={(e) => { setFieldValue('unit', e.target.value) }}
                                    />
                                    {/* {errors.unit && <span style={{ color: 'red' }}>{errors.unit && touched.unit ? errors.unit : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextInput
                                        error={errors.mrp && touched.mrp ? errors.mrp : ""}
                                        label="MRP"
                                        value={values.mrp || null}
                                        onChange={(e) => { setFieldValue('mrp', e.target.value) }}
                                    />
                                    {/* {errors.mrp && <span style={{ color: 'red' }}>{errors.mrp && touched.mrp ? errors.mrp : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextInput
                                        error={errors.purchasing_price && touched.purchasing_price ? errors.purchasing_price : ""}
                                        label="Purchasing Price"
                                        type="string"
                                        value={values.purchasing_price || null}
                                        onChange={(e) => { setFieldValue('purchasing_price', e.target.value) }}
                                    />
                                    {/* {errors.purchasing_price && <span style={{ color: 'red' }}>{errors.purchasing_price && touched.purchasing_price ? errors.purchasing_price : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <Dropdown
                                        error={errors.gst_tax_rate && touched.gst_tax_rate ? errors.gst_tax_rate : ""}
                                        label="GST TAX %"
                                        options={[{ id: '5', value: '5%' }, { id: '10', value: '10%' }, { id: '18', value: '18%' }, { id: '28', value: '28%' }]}
                                        getOptionLabel={option => option.value}
                                        value={[{ id: '5', value: '5%' }, { id: '10', value: '10%' }, { id: '18', value: '18%' }, { id: '28', value: '28%' }].find((p) => p.id === values.gst_tax_rate) || null}
                                        onChange={(e, value) => {
                                            setFieldValue('gst_tax_rate', value?.id);
                                            // console.log(value);
                                        }}
                                    />
                                    {/* {errors.gst_tax_rate && <span style={{ color: 'red' }}>{errors.gst_tax_rate && touched.gst_tax_rate ? errors.gst_tax_rate : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextInput
                                        error={errors.hsn_code && touched.hsn_code ? errors.hsn_code : ""}
                                        label="HSN Code"
                                        type="string"
                                        value={values.hsn_code || null}
                                        onChange={(e) => { setFieldValue('hsn_code', e.target.value) }}
                                    />
                                    {/* {errors.hsn_code && <span style={{ color: 'red' }}>{errors.hsn_code && touched.hsn_code ? errors.hsn_code : ""}</span>} */}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextInput
                                        error={errors.opening_stock && touched.opening_stock ? errors.opening_stock : ""}
                                        label="Opening Stock"
                                        type="string"
                                        value={values.opening_stock || null}
                                        onChange={(e) => { setFieldValue('opening_stock', e.target.value) }}
                                    />
                                    {/* {errors.opening_stock && <span style={{ color: 'red' }}>{errors.opening_stock && touched.opening_stock ? errors.opening_stock : ""}</span>} */}
                                </Grid>

                                <Grid item xs={6}>
                                    <TextInput
                                        error={errors.sales_price && touched.sales_price ? errors.sales_price : ""}
                                        label="Sales Price"
                                        type="string"
                                        value={values.sales_price || null}
                                        onChange={(e) => { setFieldValue('sales_price', e.target.value) }}
                                    />
                                    {/* {errors.sales_price && <span style={{ color: 'red' }}>{errors.sales_price && touched.sales_price ? errors.sales_price : ""}</span>} */}
                                </Grid>
                                <Grid item xs={8}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch defaultChecked />} label="Enable Low Stock Warning" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button style={{ marginRight: 10, marginLeft: 30 }} onClick={() => { navigate('/items'); }} color="error" autoFocus variant="contained">Close</Button>
                                    <LoadingButton
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                        loading={isSubmitting}
                                        color="success"
                                        autoFocus
                                        variant="contained">
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} spacing={2}>
                                {file ? <>
                                    <ClearIcon onClick={(e) => setFile('')} />
                                    <img alt='imgview' style={{ height: '300px', width:'300px' ,paddingLeft: '50px' }} src={file} /></>:
                                    ItemEditDetails?.image && <img alt='imgview' style={{ height: '300px', width:'300px' ,paddingLeft: '50px' }} src={ItemEditDetails?.image} />}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Formik>
        </Stack>
    )
}

export default ItemsForm;