import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import ConfirmDialog from "../../components/DeleteDialog";
import { CategorieState } from "../../Actions";
import { useRecoilValue } from "recoil";
import CustomTable from "../../components/Modules/CustomTable";
import { CategoriesDelete, getCategoriesData } from "../../components/Actions/CategoriesAction";
import CategoriesForm from "../../components/CategoriesForm";

const Categories = () => {
//   const expenseTypeData = useRecoilValue(ExpensesTypeState)
  const CategoriesData = useRecoilValue(CategorieState)
  const [editData, setEditData] = useState({});
  // console.log("Testing edit", editData);
  const [OpenPopup, setOpenPopup] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DeleteId, setDeleteId] = useState('');
  useEffect(() => {
    getCategoriesData()
  }, [])

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: "5%"
    },
    {
      name: 'Categories',
      selector: row => row.title,
      sortable: true,
      width: "25%"
    }, {
      name: 'Action',
      selector: row =>
        <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
          <Button onClick={() => {
            setEditData(CategoriesData.find((a) => a.id === row.id))
            setOpenAddModal(true)
          }}><LxIcon name="FaRegEdit" type="fa" /></Button>
          <Button onClick={() => {
            setDeleteId(row.id)
            setOpenPopup(true)
          }}
            color="error"><LxIcon name="BiTrash" type="bi" /></Button>
        </ButtonGroup>,
      sortable: false,
      width: "15%"
    },
  ]
  const actions = <Stack spacing={2} direction="row">
    <Button
      sx={{
        backgroundColor: "#57b8c1"
      }}
      className='btn-success'
      variant="contained"
      size='small'
      onClick={() => {
        setEditData({})
        setOpenAddModal(true)
      }}
      startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
    >
      Add New Categorie
    </Button>
    <CategoriesForm
      onClose={() => setOpenAddModal(false)}
      open={openAddModal}
      editData={editData}
    />
  </Stack>;
  const searchComponent = <OutlinedInput
    placeholder="Search"
    value={searchInput}
    onChange={(e) => setSearchInput(e.target.value)}
    size="small"
    endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
    variant="outlined"
  />
  const filteredItems = CategoriesData?.filter(item => (
    item?.title.toLowerCase().includes(searchInput.toLowerCase())
  ));
  return (
    <>
     <CustomTable
        title='Categorie List'
        columns={columns}
        data={filteredItems}
        actions={actions}
        subHeaderComponent={searchComponent}
      />
      <ConfirmDialog
        open={OpenPopup}
        onOpen={() => setOpenPopup(false)}
        onClick={() => CategoriesDelete(DeleteId, setOpenPopup)}
         />

    </>
  );
}
export default Categories;
