import React from "react";
import { useFormik } from 'formik';
import { InputAdornment, Stack } from '@mui/material';
import { BiRupee } from "react-icons/bi";
import moment from 'moment';
import * as Yup from 'yup'
import { submitData, handleProjectEdit } from "../components/Actions/ProjectAction";
import { companyState } from "../Actions";
import { useRecoilValue } from "recoil";
import Grid from '@mui/material/Grid';
import Dropdown from "./Modules/Dropdown";
import TextInput from "./Modules/TextInput";
import DateInput from "./Modules/DateInput";
import CustomDialog from "./Modules/CustomDialog";

const ProjectForm = (props) => {
    const { openAddModal, onClose, editData } = props;

    const companyData = useRecoilValue(companyState)

    const validationSchema = Yup.object().shape({
        p_name: Yup.string()
            .required('Project name is required'),
        company_id: Yup.object()
            .required('Company name is required')
            .typeError('Company name is required'),
        p_cost: Yup.string()
            .required('Project cost is required'),
        receivable_amount: Yup.string()
            .required('Receivable amount is required'),
        due_amount: Yup.string()
            .required('Due amount is required'),
        confirmation_date: Yup.date()
            .required('Date is required'),
        deadline: Yup.date()
            .required('Date is required'),
        note: Yup.string()
            .required('Note is required'),
    })

    const handleExtSubmit = (values, actions) => {
        console.log("handleExtSubmit", values);
        const data = {
            id: values.id,
            p_name: values.p_name,
            company_id: values.company_id.id,
            c_name: values.company_id.c_name,
            p_cost: values.p_cost,
            receivable_amount: values.receivable_amount,
            due_amount: values.due_amount,
            confirmation_date: values.confirmation_date,
            deadline: values.deadline,
            note: values.note,
        }
        if (values?.id) {
            handleProjectEdit(data, actions, onClose)
        } else {
            submitData(data, actions, onClose)
        }

    }

    const formik = useFormik({
        initialValues: {
            id: editData?.id,
            p_name: editData?.p_name,
            company_id: editData?.company_id ? { company_id: editData?.company_id, c_name: editData.c_name } : null,
            p_cost: editData?.p_cost,
            receivable_amount: editData?.receivable_amount,
            due_amount: editData?.due_amount,
            confirmation_date: editData?.confirmation_date ? editData?.confirmation_date : moment().format('YYYY-MM-DD'),
            deadline: editData?.deadline ? editData?.deadline : moment().format('YYYY-MM-DD'),
            note: editData?.note,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => { handleExtSubmit(values, actions) }
    })

    return (
        <Stack spacing={2} direction="row">
            <CustomDialog
                open={openAddModal}
                onClose={onClose}
                onSubmit={formik.handleSubmit}
                loading={formik.isSubmitting}
                title="Enter Your Project Details">
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={16}>
                        <TextInput
                            label="Project Name"
                            value={formik.values.p_name}
                            onChange={(e) => { formik.setFieldValue('p_name', e.target.value) }}
                        />
                        {formik.errors.p_name && <span style={{ color: 'red' }}>{formik.errors.p_name && formik.touched.p_name ? formik.errors.p_name : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={16}>
                        <Dropdown
                            label="Company name"
                            options={companyData}
                            getOptionLabel={option => option.c_name}
                            value={formik.values.company_id}
                            onChange={(e, value) => {
                                formik.setFieldValue('company_id', value);
                                // console.log(value);
                            }}
                        />
                        {formik.errors.company_id && <span style={{ color: 'red' }}>{formik.errors.company_id && formik.touched.company_id ? formik.errors.company_id : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextInput
                            label="Project Cost"
                            type={"number"}
                            value={formik.values.p_cost}
                            onChange={(e) => {
                                formik.setFieldValue('p_cost', e.target.value)
                                formik.setFieldValue('due_amount', parseFloat(e.target.value) - parseFloat(formik.values.receivable_amount))
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BiRupee />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.errors.p_cost && <span style={{ color: 'red' }}>{formik.errors.p_cost && formik.touched.p_cost ? formik.errors.p_cost : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextInput
                            label="Received Amount"
                            type={"number"}
                            value={formik.values.receivable_amount}
                            onChange={(e) => {
                                formik.setFieldValue('receivable_amount', e.target.value)
                                // Total cost minus with receive amount to 
                                formik.setFieldValue('due_amount', parseFloat(formik.values.p_cost) - parseFloat(e.target.value))
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BiRupee />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.errors.receivable_amount && <span style={{ color: 'red' }}>{formik.errors.receivable_amount && formik.touched.receivable_amount ? formik.errors.receivable_amount : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextInput
                            label="Due Amount"
                            type="number"
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BiRupee />
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.due_amount}
                            onChange={formik.handleChange("due_amount")}
                        />
                        {formik.errors.due_amount && <span style={{ color: 'red' }}>{formik.errors.due_amount && formik.touched.due_amount ? formik.errors.due_amount : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <DateInput
                            label="Start Date"
                        />
                        {/* { formik.errors.note && <span style={{ color: 'red' }}>{formik.errors.note && formik.touched.note ? formik.errors.note : ""}</span> } */}
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <DateInput
                            label="confirmation Date"
                            value={formik.values.confirmation_date}
                            onChange={(date) => {
                                formik.setFieldValue('confirmation_date', moment(date).format('YYYY-MM-DD'))
                            }}
                        />
                        {formik.errors.confirmation_date && <span style={{ color: 'red' }}>{formik.errors.confirmation_date && formik.touched.confirmation_date ? formik.errors.confirmation_date : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <DateInput
                            label="Deadline"
                            value={formik.values.deadline}
                            onChange={(date) => {
                                //console.log(date) 
                                formik.setFieldValue('deadline', moment(date).format('YYYY-MM-DD'))
                            }}
                        />
                        {formik.errors.deadline && <span style={{ color: 'red' }}>{formik.errors.deadline && formik.touched.deadline ? formik.errors.deadline : ""}</span>}
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Dropdown
                            label="Payment Type"
                        />
                        {/* { formik.errors.note && <span style={{ color: 'red' }}>{formik.errors.note && formik.touched.note ? formik.errors.note : ""}</span> } */}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextInput
                            minRows={5}
                            label="Note"
                            multiline
                            value={formik.values.note}
                            onChange={formik.handleChange('note')}
                        />
                        {formik.errors.note && <span style={{ color: 'red' }}>{formik.errors.note && formik.touched.note ? formik.errors.note : ""}</span>}
                    </Grid>
                </Grid>
            </CustomDialog>
        </Stack>
    )
}
export default ProjectForm;