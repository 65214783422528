
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { PaybleState } from '../../Actions';
import AxiosApi from '../../Actions/AxiosApi';
export const getData = () => {
    AxiosApi.get("payable-account")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(PaybleState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })
}
export const submitdata = (values, actions, onClose) => {
    AxiosApi.post("payable-account", values)
        .then(function ({ data }) {
            actions.setSubmitting(false)
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })

}
export const handleDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`payable-account/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getData()
                toast.success(data.message);
                setOpenPopup(false)
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })

}
