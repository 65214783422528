import React, { useState, useEffect } from "react";
import moment from 'moment';
import ExpensesForm from "../../components/ExpensesForm";
import { Button, InputAdornment, OutlinedInput, ButtonGroup } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import { getExpensesTypeData } from "../../components/Actions/ExpensesTypeAction";
import ConfirmDialog from "../../components/DeleteDialog";
import { getExpensesData, handleExpensesDelete } from "../../components/Actions/ExpensesAction";
import { ExpensesState } from "../../Actions";
import { useRecoilValue } from "recoil";
import { ExpensesTypeState } from "../../Actions";
import CustomTable from "../../components/Modules/CustomTable";

export default function FadeMenu() {
  const expenses = useRecoilValue(ExpensesState)
  const [editData, setEditData] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [OpenPopup, setOpenPopup] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [DeleteId, setDeleteId] = useState('');
  useEffect(() => {
    getExpensesData()
    getExpensesTypeData(ExpensesTypeState)
  }, [])

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: "5%"
    },
    {
      name: 'Expense Title',
      selector: row => row?.expense_type_info?.title,
      sortable: true,
      width: "10%"
    },
    {
      name: 'Paid to',
      selector: row => row.paid_to,
      sortable: true,
      width: "15%"
    },
    {
      name: 'Paid by',
      selector: row => row.paid_by,
      sortable: true,
      width: "15%"
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      width: "10%"
    },
    {
      name: 'Date',
      selector: row => moment(row.date, 'YYYY-MM-DD').format("DD-MM-YYYY"),
      sortable: true,
      width: "10%"
    },
    {
      name: ' Notes',
      selector: row => row.note,
      sortable: true,
      width: "20%"
    },
    {
      name: 'Action',
      selector: row =>
        <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
          <Button onClick={() => {
            setEditData(expenses.find((a) => a.id === row.id))
            setOpenAddModal(true)
          }}><LxIcon name="FaRegEdit" type="fa" /></Button>
          <Button onClick={() => {
            setDeleteId(row.id)
            setOpenPopup(true)
          }}
            color="error"><LxIcon name="BiTrash" type="bi" /></Button>
        </ButtonGroup>,
      sortable: false,
      width: "15%"
    },
  ]
  // const customStyles = {
  //   header: {
  //     style: {
  //       minHeight: '40px',
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       height: '10px',
  //       minHeight: '40px',
  //       backgroundColor: "#57b8c1"
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       fontSize: '12px',
  //       fontWeight: '600',
  //       padding: '6px'
  //     },
  //   }
  // }
  // const conditionalRowStyles = [
  //   {
  //     when: row => row.UserStat === 'Inactive',
  //     style: {
  //       backgroundColor: '#f3f3f3',
  //       color: 'rgb(132 132 132 / 87%)'
  //     },
  //   }
  // ]
  const actions =
    <Button
      sx={{
        backgroundColor: "#57b8c1"
      }}
      className='btn-success'
      variant="contained"
      size='small'
      onClick={() => {
        setEditData({})
        setOpenAddModal(true)
      }}
      startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
    >
      Add New
    </Button>
  const searchComponent = <OutlinedInput
    placeholder="Search"
    value={searchInput}
    onChange={(e) => setSearchInput(e.target.value)}
    size="small"
    endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
    variant="outlined"
  />
  const filteredItems = expenses?.filter(item => (
    item?.paid_to.toLowerCase().includes(searchInput.toLowerCase())
  ));
  return (
    <>
      <CustomTable title='Expenses Details'
        columns={columns}
        data={filteredItems}
        actions={actions}
        subHeaderComponent={searchComponent}
      />
      {/* <DataTable
        title='Expenses Details'
        actions={actions}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={filteredItems}
        fixedHeader
        persistTableHead
        subHeader
        subHeaderComponent={searchComponent}
        paginationResetDefaultPage={true}
        pagination={true}
        currentPage={1}
        paginationPerPage={10}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All'
        }}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      /> */}

      <ExpensesForm
        openAddModal={openAddModal}
        editData={editData}
        onClose={() => setOpenAddModal(false)}
      />
      <ConfirmDialog
        open={OpenPopup}
        onOpen={() => setOpenPopup(false)}
        onClick={() => handleExpensesDelete(DeleteId, setOpenPopup)} />
    </>

  );
}