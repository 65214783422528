
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { ExpensesTypeState } from '../../Actions';
import AxiosApi from '../../Actions/AxiosApi';
export const getExpensesTypeData = () => {
    AxiosApi.get("expense-type")
        .then(function ({ data }) {
            if (data.status) {
                setRecoil(ExpensesTypeState, data.data)
                // console.log(data.data);
            }
        })
        .catch(error => {
            console.log("expense-type", error);
        })
}
export const submitdata = (values, actions, onClose) => {
    AxiosApi.post("expense-type-add", values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesTypeData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("expense-type-add", error);
        })

}
export const handleExpensesTypeEdit = (values, actions, onClose) => {
    AxiosApi.patch(`expense-type/${values?.id}`, values)
        .then(function ({ data }) {
            if (data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesTypeData()
                toast.success(data.message);
            }
        })
        .catch(error => {
            console.log("expense-type-add", error);
        })

}
export const handleExpensesTypeDelete = (id, setOpenPopup) => {
    AxiosApi.delete(`expense-type/${id}`)
        .then(function ({ data }) {
            if (data.status) {
                getExpensesTypeData()
                toast.success(data.message);
                setOpenPopup(false);
            } else {
                toast.error(data.message);
            }
        })
        .catch(error => {
            console.log("Get Payble", error);
        })

}
