import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';
import { LxIcon } from '../../../resources/LxIcon';
import { colors } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { layoutState } from '../../../Actions/Atoms';
import { useRecoilState } from 'recoil';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // backgroundColor: '#000',
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AppSidebar() {

  const theme = useTheme();
  const location = useLocation();
  // console.log(location.pathname)
  const [toggleMaenu, setToggleMenu] = useRecoilState(layoutState)

  const handleDrawerClose = () => {
    setToggleMenu(false);
  };

  return (
    <Drawer variant="permanent" open={toggleMaenu}>
      <DrawerHeader variant="dense">
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <LxIcon name="RiMenu4Line" type="ri" /> : <LxIcon name="RiMenuLine" type="ri" />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <React.Fragment className="nav-link">
        
        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/dashboard">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiDashboard3Line" RiDashboard3Line
                type="ri"
                size={30}
                color={location?.pathname === "/dashboard" ? deepPurple[700] : "#000000"} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/projects">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiProfileLine"
                type="ri"
                size={30}
                color={location?.pathname === "/projects" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
        </NavLink>
        {/* <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/statements">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiStackFill"
                type="ri"
                size={30}
                color={location?.pathname === "/statements" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Statements" />
          </ListItem>
        </NavLink> */}

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/expense-type">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiExchangeBoxFill"
                type="ri"
                size={30}
                color={location?.pathname === "/expense-type" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="ExpenseType" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/expenses">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiExchangeBoxLine"
                type="ri"
                size={30}
                color={location?.pathname === "/expenses" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Expenses" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/company">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiCommunityFill"
                type="ri"
                size={30}
                color={location?.pathname === "/company" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Company" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/payment-mode">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiCommunityFill"
                type="ri"
                size={30}
                color={location?.pathname === "/payment-mode" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Payment Mode" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/payment">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentLine"
                type="ri"
                size={30}
                color={location?.pathname === "/payment" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Payment" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "Black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/payable-account">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                size={30}
                type="ri"
                color={location?.pathname === "/payable-account" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Payable Account" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "Black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/categories">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                size={30}
                type="ri"
                color={location?.pathname === "/categories" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </ListItem>
        </NavLink>

<NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/vendor">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                type="ri"
                size={30}
                color={location?.pathname === "/vendor" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Vendor" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "Black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/items">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                size={30}
                type="ri"
                color={location?.pathname === "/items" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Items" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "Black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/invoice">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                size={30}
                type="ri"
                color={location?.pathname === "/invoice" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Invoice" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => {
            return {
              display: "block",
              color: isActive ? colors.deepPurple : "Black",
              fontWeight: 'bold',
              textDecoration: 'none',
            };
          }}
          to="/purchase">
          <ListItem>
            <ListItemIcon>
              <LxIcon
                name="RiSecurePaymentFill"
                size={30}
                type="ri"
                color={location?.pathname === "/purchase" ? deepPurple[700] : "#000"} />
            </ListItemIcon>
            <ListItemText primary="Purchase" />
          </ListItem>
        </NavLink>

      </React.Fragment>
    </Drawer>
  );
}
